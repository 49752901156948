$gnav-height-md: 108px;
$gnav-height-xs: 55px;

#allbox {
  // ナビバーが画面固定されたときのクリアランス
  padding-top: 108px;
  @include media-down(md) {
    padding-top: 55px;
  }
}

.rwd002-header {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  padding-left: 0;
  padding-right: 0;
  box-shadow: 0 0 10px rgba($black, 7%);
  z-index: 100;
  background-color: $white;
    
  

  @include media-up(lg) {
    padding-left: min(map-get($spacers, 4), 4vw);
    padding-right: 0;
  }

  @include media-down(lg) {
    height: 108px;
    overflow: hidden;
  }

  @include media-down(md) {
    height: 55px;
  }

  &__logo {
    flex-grow: 1;
    margin: 0;
    max-height: 100%;

    &.is-img,
    &.is-text {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-top: map-get($spacers, 2);
      padding-bottom: map-get($spacers, 2);

      .img{
      width: clamp(12.5rem, 20vw, 18.75rem);
      }

      @include media-down(lg) {
        padding-top: 0;
        padding-bottom: 0;
      }

    }

    &.is-img {
      @include media-down(lg) {
        height: 100%;
        img {
          display: block;
          object-fit: contain;
          max-height: 100%;
          max-width: 100%;
        }
      }
    }

    &.is-img:hover,
    &.is-img:focus,
    &.is-text:hover,
    &.is-text:focus {
      @include hoverOpacity;
    }

    &.is-block {
      @include media-down(lg) {
        height: 100%;
      }
    }

    .hidden-top-sm {
      @include media-up(lg) {
        display: block;
      }
    }
    .visible-top-sm {
      @include media-up(lg) {
        display: none;
      }
    }
    .is--home & .visible-top-sm {
      @include media-up(lg) {
        display: block !important;
      }
    }
    .is--home & .hidden-top-sm {
      @include media-up(lg) {
        display: none;
      }
    }
  }

  &__logo-link {
    &:hover,
    &:focus {
      text-decoration: none;
    }
    .is-img & {
      display: block;
      @include media-down(lg) {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: map-get($spacers, 1);
        padding-bottom: map-get($spacers, 1);
        padding-left: map-get($spacers, 2);
        padding-right: map-get($spacers, 2);
        height: 100%;
      }
    }
    .is-text & {
      display: inline-flex;
      flex-direction: column;
      line-height: 1.8;
      letter-spacing: 0.2em;
      font-feature-settings: "palt";
      font-size: 2rem;
      small {
        font-size: $font-size-sm;
        line-height: 1.2;
        letter-spacing: 0.05em;
        color: fade-out($body-color, 0.45);
      }
      @include media-down(lg) {
        font-size: $font-size-base;
        line-height: 1.5;
        small{
          display: none;
        }
      }
    }
  }

}

.rwd002-header__drawerBtn {
  @extend .btn;
  height: 100%;
  position: relative;
  z-index: 101;
  
  @include media-down(lg) {
    width: 100px;
    background-color: fade-out($white, 0.5);
  }

  @include media-down(md) {
    width: 55px;
  }

  &:focus {
    box-shadow: none;
  }

  @include media-up(lg) {
    display: none;
  }

  &__bars {
    stroke: $body-color;
  }

  &__bar01,
  &__bar02,
  &__bar03 {
    transform-origin: center;
    transition: $transition-base;
  }

  .is-active &__bar01 {
    transform: rotate(135deg) translateY(50%);
  }

  .is-active &__bar02 {
    transform: rotate(45deg);
    opacity: 0;
  }

  .is-active &__bar03 {
    transform: rotate(225deg) translateY(-50%);
  }
}

.header-contact-link {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  background-color: $primary;
  width: 55px;
  height: 100%;
  &:hover,&:focus{
    color: $white;
  }
  @include media-up(md) {
    width: 100px;
  }
  @include media-up(lg) {
    display: none;
  }
}

.content-header {
  display: flex;
  justify-content: center;
  padding: map-get($spacers, 2) map-get($spacers, 3);
  @include media-up(lg) {
    height: 220px;
  }
  @include media-down(lg) {
    height: 128px; 
  }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    .is--technology &,
    .is--materials &,
    .is--news &,
    .is--recruit &,
    .is--inquiry &,
    .is--sitemap &,
    .is--privacy &{
      justify-content: center;
    }
  }
  &__title {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 1em;
    font-size: min(7.46vw, calc(40 / 16 * $font-size-base));
    letter-spacing: 0.12em;
    color: $primary;
    &:last-child{
      margin-bottom: 0;
    }
    span{
      font-family: 'Montserrat', $font-family-base;
      font-weight: $font-weight-medium;
    }
    small {
      color: $body-color;
      font-size: min(3.73vw, $font-size-base);
      letter-spacing: 0.18em;
      margin-top: 0.5em;
    }

    .is--technology &,
    .is--materials &,
    .is--news &,
    .is--recruit &,
    .is--inquiry &,
    .is--sitemap &,
    .is--privacy &{
      align-items: center;
    }
  }
}

.is--sitemap .rwd002-moduletitle,
.is--privacy .rwd002-moduletitle {
  display: none;
}

.page-intro {
  position: relative;
  z-index: 0;
  &:after {
    content: '';
    position: absolute;
    top: 125px;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #F6F8FA;
    z-index: -1;
    @include media-down(lg) {
      top: 85px;
    }
  }
  &__img {
    width: 100%;
    height: 468px;
    max-width: calc(100% - 125px);
    object-fit: cover;
    @include media-down(lg) {
      max-width: calc(100% - 25px);
      height: 206px;
    }
  }
}