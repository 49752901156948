.is--technology {
  .rwd002-detail {
    margin-top: 0;
  }
  .editor-template {
    font-size: inherit !important;
  }
}

.tech-list-container {
  padding: 0 map-get($spacers, 3);
}


.tech-list-categoryList {
}

.tech-list-categroup {
  max-width: 1100px;
  margin: 0 auto map-get($spacers, 6);
  &__name {
    display: block;
    font-size: min(5.86vw,calc(26 / 16 * $font-size-base));
    font-weight: $font-weight-bold;
    letter-spacing: 0.18em;
    color: #627597;
    padding-left: 0.8em;
    border-left: 5px solid;
    margin-bottom: 1.5em;
    @include media-down(lg) {
      border-left-width: 4px;
    }
    &:hover,&:focus{
      text-decoration: underline;
      text-underline-offset: 0.2em;
    }
  }
}

.tech-list-itemlist {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  column-gap: 98px;
  row-gap: 55px;
  list-style: none;
  @include media-down(lg) {
    column-gap: 34px;
  }
}

.tech-list-item {
  max-width: 301px;
  @include media-down(lg) {
    max-width: calc(50% - 17px);
  }
  &__thumb{
    overflow: hidden;
    margin-bottom: 1em;
  }
  .img{
    display: block;
    width: 100%;
    height: 301px;
    object-fit: cover;
    transition: $transition-base;
    @include media-down(lg) {
      height: auto;
      aspect-ratio: 1;
    }
  }
  &__link:hover,&__link:focus{
    text-decoration: none;
    text-underline-offset: 0.2em;
  }
  &__link:hover .img, &__link:focus .img{
    transform: scale(1.1);
  }
  &__link:hover .title, &__link:focus .title{
    text-decoration: underline;
  }
  &__body {
    display: flex;
    flex-direction: column;
    font-feature-settings: "palt";
    row-gap: 1em;
  }

  .title {
    color: #4E638A;
    font-weight: $font-weight-bold;
    letter-spacing: 0.12em;
    font-size: min(4.53vw, calc(24 / 16 * $font-size-base));
  }
  .icon {
    margin-left: 0.5em;
    vertical-align: -0.1em;
  }
  .desc {
    font-size: min( 3.2vw, calc(13/ 16 * $font-size-base));
    letter-spacing: 0.12em;
    line-height: 1.7;
  }
}

.tech-detail-head {
  max-width: 960px;
  margin: map-get($spacers, 5) auto 0;
  @include media-down(lg) {
    margin-top: map-get($spacers, 4);
  }

  &__body {
    margin-bottom: map-get($spacers, 4);
    @include media-down(lg) {
      margin-bottom: map-get($spacers, 2);
    }
    .cat{
      display: block;
      color: $primary;
      font-size: calc(14 / 16 * $font-size-base);
      letter-spacing: 0.12em;
      font-weight: $font-weight-bold;
      @include media-up(lg) {
        margin-bottom: 0.7em;
      }
      .fa-tag {
        margin-right: 0.3em;
      }
    }
    .subject {
      position: relative;
      display: block;
      font-size: min(5.86vw,calc(26 / 16 * $font-size-base));
      font-weight: $font-weight-bold;
      letter-spacing: 0.18em;
      color: #627597;
      padding-bottom: 0.5em;
      margin-bottom: 1.5em;
      text-align: center;
      @include media-down(lg) {
        border-left-width: 4px;
      }
      &:after{
        content: '';
        position: absolute;
        top: 100%;
        left: calc(50% - 0.8em);
        border-bottom: 1px solid;
        width: 1.6em;
      }
    }
  }

  &__image {
    max-width: 100%;
    margin-bottom: map-get($spacers, 4);
    @include media-down(lg) {
      margin-bottom: map-get($spacers, 2);
    }

    .img {
      display: block;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
    }
  }

  &__keywords-list {
    display: flex;
    column-gap: 1em;
    row-gap: 0.5em ;
    list-style: none;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: map-get($spacers, 3);
    
    .tag {
      font-feature-settings: "palt";
      display: block;
      text-align: center;
      font-size: min(2.66vw, calc(14 / 16 * $font-size-base));
      letter-spacing: 0.12em;
      color: #627597;
      border: 1px solid #CAD0DC;
      border-radius: 3px;
      transition: $transition-base;
      padding: 0.3em 1em;
      @include media-up(lg) {
        min-width: 150px;
      }
      &.current,&:hover,&:focus{
        background-color: #627597;
        border-color: #627597;
        color: $white;
        text-decoration: none;
      }
    }
  }

  &__keywords-item{
    @include media-down(lg) {
      min-width: calc(25% - 0.75em);
    }
  }
}

.tech-detail {
  max-width: 960px;
  margin: 0 auto;
  font-size: calc(15 / 16 * $font-size-base);
  line-height: 1.9;
  letter-spacing: 0.12em;
  font-feature-settings: "palt";

  h4 {
    position: relative;
    font-size: min(4.8vw, calc(22 / 16 * $font-size-base));
    color: #627597;
    letter-spacing: 0.24em;
    font-weight: $font-weight-bold;
    padding-left: 1em;
    margin-bottom: 1.5em;
    &:before {
      content: '';
      position: absolute;
      top: 0.6em;
      left: 0;
      width: 0.4em;
      height: 2px;
      background-color: currentColor;
    }
  }
}

.tech-table-typeA {
  @include table-softpren-typeA;
}

.tech-table-typeB{
  @include table-softpren-typeB;
}


.tech-table-scrollable {
  @include media-down(lg) {
    position: relative;
    max-width: 100%;
    padding-top: 30px;
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      content: "";
      background: svgUrlFunc(
          '<svg viewBox="0 0 52.74 16.16" xmlns="http://www.w3.org/2000/svg"><g fill="#{$body-color}"><path d="m52.74 16.16h-52.74v-1h50.96l-2.54-4.19.86-.52z"/><path d="m5.44 7.32c0-.29-.11-.52-.33-.68s-.61-.3-1.15-.42-.98-.26-1.31-.42-.56-.36-.71-.59-.23-.5-.23-.81c0-.52.22-.96.66-1.32s1-.54 1.68-.54c.72 0 1.3.19 1.75.56s.67.85.67 1.42h-1.09c0-.3-.13-.55-.38-.77s-.57-.32-.95-.32-.7.09-.93.26-.33.4-.33.67c0 .26.1.46.31.59s.58.26 1.12.38.98.27 1.32.43.58.37.75.61.24.52.24.86c0 .57-.23 1.02-.68 1.36s-1.04.51-1.76.51c-.51 0-.96-.09-1.35-.27s-.7-.43-.92-.75-.33-.67-.33-1.05h1.08c.02.36.17.65.44.86s.63.32 1.08.32c.41 0 .74-.08.99-.25s.37-.39.37-.67z"/><path d="m11.92 8.23c.39 0 .72-.12 1.01-.35s.45-.53.48-.88h1.03c-.02.36-.14.71-.38 1.04s-.54.59-.92.79-.79.29-1.22.29c-.86 0-1.55-.29-2.06-.86s-.76-1.36-.76-2.36v-.18c0-.62.11-1.17.34-1.65s.55-.85.98-1.12.92-.4 1.5-.4c.71 0 1.3.21 1.77.64s.72.98.75 1.66h-1.03c-.03-.41-.19-.75-.47-1.01s-.62-.4-1.03-.4c-.55 0-.98.2-1.28.59s-.45.97-.45 1.72v.21c0 .73.15 1.29.45 1.69s.73.59 1.29.59z"/><path d="m20.16 3.63c-.16-.03-.34-.04-.53-.04-.71 0-1.19.3-1.45.91v4.5h-1.08v-6.34h1.05l.02.73c.36-.57.86-.85 1.51-.85.21 0 .37.03.48.08z"/><path d="m22.31 5.77c0-.62.12-1.18.37-1.68s.58-.88 1.02-1.15.93-.4 1.49-.4c.86 0 1.56.3 2.09.9s.8 1.39.8 2.38v.08c0 .62-.12 1.17-.35 1.66s-.57.87-1.01 1.15-.94.41-1.51.41c-.86 0-1.56-.3-2.09-.9s-.8-1.39-.8-2.37v-.08zm1.09.13c0 .7.16 1.27.49 1.69s.76.64 1.31.64.99-.22 1.31-.65.49-1.04.49-1.81c0-.7-.17-1.26-.5-1.69s-.77-.65-1.32-.65-.97.21-1.29.64-.49 1.04-.49 1.83z"/><path d="m32.06 9h-1.08v-9h1.08z"/><path d="m36.41 9h-1.08v-9h1.08z"/></g></svg>'
        )
        center / cover no-repeat;
      width: 53px;
      height: 16px;
      animation: scroll_table_icon 1s infinite ease-in-out alternate-reverse;
    }
  }
  &__inner {
    @include media-down(lg) {
      overflow: auto;
    }
  }
  &__table {
    @include media-down(lg) {
      width: 800px;
    }
  }
}