@function svg-right-angle($theme: $primary) {
  @return svgUrlFunc(
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$theme}" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>'
  );
}

@function svg-bottom-angle($theme: $body-color) {
  @return svgUrlFunc('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$theme}" d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"/></svg>');
}

@function svg-right-arrow($theme: $body-color) {
  @return svgUrlFunc('<svg viewBox="0 0 12.33 4.29" xmlns="http://www.w3.org/2000/svg"><path d="m.55 3.74h11.23l-2.55-3.19" fill="none" stroke="#{$theme}" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.11"/></svg>');
}

.btn-icon-right {
  position: relative;
  padding-right: 2.5em;
  padding-left: 2em;
  .icon{
    position: absolute;
    top: 50%;
    right: 1em;
    transform: translateY(-50%);
  }
}

.btn-icon-right-angle {
  position: relative;
  &:after{
    position: absolute;
    content: "";
    top: calc(50% - 0.3em);
    right: 1em;
    width: 1em;
    height: 0.6em;
    background: center / contain no-repeat;
    background-image: svg-right-angle();
  }
  &:hover:after,&:focus:after{
    background-image: svg-right-angle($white);
  }
}
.btn-icon-right-arrow {
  position: relative;
  &:after{
    position: absolute;
    content: "";
    top: calc(50% - 0.3em);
    right: 1em;
    width: 1em;
    height: 0.6em;
    background: center / contain no-repeat;
    background-image: svg-right-arrow();
  }
  &:hover:after,&:focus:after{
    background-image: svg-right-arrow($white);
  }
}

.btn-icon-right-angle-white{
  @extend .btn-icon-right-angle;
  &:after{
    background-image: svg-right-angle($white);
  }
}

.btn-icon-bottom-angle {
  position: relative;
  &:after{
    position: absolute;
    content: "";
    top: calc(50% - 0.3em);
    right: 0;
    width: 2em;
    height: 0.6em;
    transition: all .5s ease-in-out;
    background: center / contain no-repeat;
    background-image: svg-bottom-angle();
    transform: rotateX(180deg);
  }
  &.collapsed:after{
    transform: rotateX(0deg);
  }
}

.btn-pill {
  border-radius: 100em;
}

.btn-outline-light {
  color: $body-color;
}