@import 'rwd002';

// webfonts
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500&family=Montserrat:wght@500;700&family=League+Spartan:wght@300;500&family=Roboto+Condensed&display=swap');

@import './components/nav';
@import './components/table';

@import './pages/home';
@import './pages/about';
@import './pages/company';
@import './pages/recruit';
@import './pages/technology';
@import './pages/materials';