.page-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: map-get($spacers, 3);
  row-gap: map-get($spacers, 2);
  padding: 0 map-get($spacers, 3);
  margin: map-get($spacers, 5) 0;
  @include media-down(lg) {
    margin-bottom: 0;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    flex-basis: 49%;
    max-width: 466px;
    height: 87px;
    padding: 1em 2em;
    letter-spacing: 0.28em;
    text-indent: 0.28em;
    @include media-down(lg) {
      height: 60px;
      font-size: calc(14 / 16 * $font-size-base);
    }
    &.current {
      background-color: $quaternary;
      color: $white;
    }
    span {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0.4em;
        right: calc(100% + 0.5em);
        height: 0.64em;
        width: 0.64em;
        background-color: currentColor;
        border-radius: 100%;
      }
    }
  }
}


.list-categoryNav {
  display: grid;
  column-gap: map-get($spacers, 3);
  row-gap: 1em;
  max-width: 1100px;
  margin: 0 auto map-get($spacers, 6);
  @include media-up(lg) {
    grid-template-columns: auto 1fr;
  }
  @include media-down(lg) {
    margin-bottom: map-get($spacers, 4);
    flex-direction: column;
  }
  &__title {
    position: relative;
    font-family: Montserrat, $font-family-base;
    font-size: calc(14 / 16 * $font-size-base);
    font-weight: $font-weight-medium;
    letter-spacing: 0.16em;
    margin: 0;
    padding-top: 0.4em;
    padding-left: 2em;
    @include media-up(lg) {
      grid-row: span 2;
    }
    &:after{
      content: '';
      position: absolute;
      top: 0.8em;
      left: 5px;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background-color: $primary;
      box-shadow: 0 0 0 5px tint($primary, 80%);
    }
  }
  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    column-gap: 14px;
    row-gap: 10px;
    flex-grow: 1;
    flex-wrap: wrap;
    @include media-up(lg) {
      grid-row: 1;
      grid-column: 2;
    }
  }

  &__item {
    @include media-up(lg) {
      flex-basis: calc(20% - 12px);
    }
    @include media-down(lg) {
      flex-basis: calc(50% - 7px);
    }
    @include text-truncate;
  }

  .link {
    width: 100%;
    font-feature-settings: "palt";
    display: block;
    text-align: center;
    font-size: min(3.46vw, calc(14 / 16 * $font-size-base));
    letter-spacing: 0.12em;
    color: #627597;
    border: 1px solid #CAD0DC;
    border-radius: 3px;
    transition: $transition-base;
    padding: 0.3em 1em;
    &.current,&:hover,&:focus{
      background-color: #627597;
      border-color: #627597;
      color: $white;
      text-decoration: none;
    }
  }

  &__tags {
    font-feature-settings: "palt";
    @include media-up(lg) {
      grid-row: 2;
      grid-column: 2;
      margin-top: map-get($spacers, 2);
    }
  }

  
  .tag {
    display: inline-block;
    color: #989899;
    font-size: min(3.2vw, calc(13 / 16 * $font-size-base));
    letter-spacing: 0.1em;
    text-underline-offset: 0.2em;
    &.current {
      text-decoration: underline;
    }
  }

  &__btnwrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: map-get($spacers, 2);
  }

  &__tglBtn {
    @extend .btn;
    @extend .btn-link;
    @extend .btn-sm;
    text-underline-offset: 0.2em;
  }
}