@keyframes slider {
  from {transform: translateX(0);}
  to {transform: translateX(-3852px - 15px);}
}
@keyframes slider_sp {
  from {transform: translateX(0);}
  to {transform: translateX(-2021px - 10px);}
}

.is--company {
  .rwd002-pagination {
    display: none;
  }
}

.company-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 map-get($spacers, 3);
  margin: map-get($spacers, 5) auto;
  column-gap: map-get($spacers, 2);
  row-gap: map-get($spacers, 2);
  .page-intro &{
    margin-bottom: 0;
  }
  .btn {
    font-weight: $font-weight-normal;
    font-size: calc(13 / 16 * $font-size-base);
    width: 100%;
    max-width: 238px;
    padding-top: 0.8em;
    padding-bottom: 0.8em;
    letter-spacing: 0.17em;
    @include media-down(lg) {
      flex-basis: 150px;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      padding-left: 1.8em;
      padding-right: 2.3em;
      flex-grow: 1;

    }
  }
}

.message-intro {
  padding: map-get($spacers, 6) map-get($spacers, 3) 120px;
  background-color: #f6f8fa;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  @include media-up(lg) {
    row-gap: map-get($spacers, 5);
    writing-mode: vertical-rl;
  }
  @include media-down(lg) {
    display: grid;
    grid-template: auto / auto;
    place-items: center;
    column-gap: 1em;
    padding: map-get($spacers, 3) map-get($spacers, 3) map-get($spacers, 4);
  }
  &__title {
    @include c-section-title();
    @include media-up(lg) {
      flex-direction: column-reverse;
      align-items: flex-start;
      margin-bottom: 0;
      margin-block: 1em 0;
    }
    @include media-down(lg) {
      grid-column: 1 / span 2;
      margin-bottom: 1.5em;
    }
    span {
      @include media-up(lg) {
        text-indent: 0;
      }
    }
    small {
      @include media-up(lg) {
        margin-top: 0;
        margin-block-end: 1em;
      }
    }
  }

  &__lead {
    font-family: $font-family-serif;
    font-size: min(4vw, calc(17 / 16 * $font-size-base));
    line-height: 3;
    letter-spacing: 0.14em;
    margin: 0;
    margin-block: 0;
    @include media-down(lg) {
      writing-mode: vertical-rl;
      line-height: 2;
      grid-row: 2;
      grid-column: 2;
      margin-block-start: 1em;
    }
  }

  &__signature {
    align-self: flex-end;
    @include media-up(lg) {
      margin-block: 1em 0;
    }
    @include media-down(lg) {
      grid-row: 2;
      grid-column: 1;
      max-width: 16vw;
    }
    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
}

.company-message {
  margin: map-get($spacers, 7) 0;
  padding: 0 map-get($spacers, 3);
  @include media-down(lg) {
    margin: map-get($spacers, 6) 0;
  }
  &__title {
    @include c-section-title;
  }

  &__inner {
    max-width: 800px;
    margin: 0 auto;
  }

  &__photo {
    flex-shrink: 0;
    img{
      display: block;
      max-width: 100%;
      height: auto;
      margin: 0 auto map-get($spacers, 3);
    }
  }

  &__profile {
    font-weight: $font-weight-normal;
    font-feature-settings: "palt";
    margin-bottom: 5em;
    @include media-down(lg) {
      margin-bottom: 3em;
    }
    .title {
      font-size: min(3.2vw, calc(13 / 16 * $font-size-base));
      letter-spacing: 0.14em;
      margin-bottom: 0.3em;
    }
    .name {
      font-size: min(7.2vw, calc(29 / 16 * $font-size-base));
      font-family: $font-family-serif;
      letter-spacing: 0.14em;
      margin-bottom: 0.5em;
      small{
        font-family: 'Roboto', $font-family-base;
        font-size: calc(12 / 16 * $font-size-base);
        letter-spacing: 0.18em;
        color: tint($body-color, 60%);
        font-weight: $font-weight-medium;
        margin-left: 1em;
      }
    }
    .profile {
      font-size: calc(13 / 16 * $font-size-base);
      font-weight: $font-weight-normal;
      letter-spacing: 0.18em;
      color: tint($body-color, 60%);
    }
    p:last-child {
      margin-bottom: 0;
    }
  }

  &__body {
    font-feature-settings: "palt";
    font-size: calc(15 / 16 * $font-size-base);
    line-height: 1.9;
    letter-spacing: 0.12em;
    margin-bottom: map-get($spacers, 6);
    @include media-down(lg) {
      margin-bottom: map-get($spacers, 5);
    }
    h4 {
      font-weight: $font-weight-bold;
      letter-spacing: 0.18em;
      margin-bottom: 1.5em;
    }
    p{
      margin-bottom: 2em;
    }
    p+h4 {
      margin-top: 3em;
    }
  }

  &__directors {
    display: flex;
    column-gap: 90px;
    row-gap: map-get($spacers, 4);
    justify-content: center;
    padding-top: map-get($spacers, 6);
    margin-top: map-get($spacers, 6);
    border-top: 1px solid #EDEDED;
    @include media-up(lg) {
      margin-left: -1 * map-get($spacers, 5);
      margin-right: -1 * map-get($spacers, 5);
    }
    @include media-down(lg) {
      flex-direction: column;
      align-items: center;
      padding-top: map-get($spacers, 4);
      margin-top: map-get($spacers, 4);
    }
  }
  &__directors &__photo {
    @include media-up(lg) {
      max-width: 355px;
    }
  }
  &__directors &__profile {
    margin-bottom: 0;
  }
}

.company-policy {
  padding: map-get($spacers, 7) map-get($spacers, 3) map-get($spacers, 7);
  margin-bottom: map-get($spacers, 6);
  background-color: #f6f8fa;
  @include media-down(lg) {
    padding-top: map-get($spacers, 6);
    padding-bottom: 0;
    margin-bottom: map-get($spacers, 5);
  }
  &__title {
    @include c-section-title;
  }

  &__head {
    margin-bottom: map-get($spacers, 7);
    @include media-down(lg) {
      margin-bottom: map-get($spacers, 6);
    }
  }

  &__certs {
    display: flex;
    align-items: center;
    justify-content: center;
    [rel="lightbox"]{
      transition: $transition-base;
    }
    [rel="lightbox"]:hover{
      opacity: 0.7;
    }
    img{
      display: block;
      height: auto;
      max-width: 100%;
    }
  }

  &__quality,
  &__environment {
    padding: map-get($spacers, 7) 0;
    margin-bottom: map-get($spacers, 7);
    z-index: 0;
    display: grid;
    grid-template: auto / auto;
    column-gap: 70px;
    row-gap: 45px;
    transition: color 0.2s 0.3s ease-in-out;
    &:last-child{
      margin-bottom: 0;
    }
    @include media-up(lg) {
      justify-content: center;
    }
    @include media-down(lg) {
      padding-top: 0;
      padding-bottom: map-get($spacers, 5);
      margin-bottom: map-get($spacers, 5);
    }

    &:before,&:after {
      content: '';
      position: absolute;
    }

    &:before {
      opacity: 0;
      transform: translateY(100%);
      transition: transform 0.8s 0.5s ease-in-out, opacity 0.8s 0.5s ease-in-out;

    }
    &:after{
      top: 0;
      bottom: 0;
      z-index: -1;
      background-color: #09246C;
      transform: scale(0,1);
      transition: transform 0.5s ease-in-out;
      @media (max-width: 960px){
        right: -1 * map-get($spacers, 3);
      }
      @include media-down(lg) {
        top: 105px;
        bottom: 0;
      }
    }
  }

  &__quality {
    &:before {
      right: calc(50vw - 480px - map-get($spacers, 3));
      bottom: calc(100% - 17px);
      background: url('/assets/img/company/company-quality-title.svg') left top / contain no-repeat;
      width: 309px;
      height: 72px;
      @include media-down(lg) {
        right: auto;
        left: -10px;
        height: 40px;
      }
      @media (max-width: 960px){
        right: -1 * map-get($spacers, 3);
      }
    }
    &:after{
      right: calc(50vw - 480px - map-get($spacers, 3));
      left: -1 * map-get($spacers, 3);
      transform-origin: left;
      @media (max-width: 960px) {
        right: -1 * map-get($spacers, 3);
      }
    }
    &.lazyhide {
      opacity: 1;
    }
    &.is-intersection{
      color: $white;
      &:before{
        opacity: 1;
        transform: translateY(0);
      }
      &:after {
        transform: scale(1,1);
      }
    }
  }

  &__environment {
    &:before {
      left: calc(50vw - 480px - map-get($spacers, 3));
      bottom: calc(100% - 1px);
      background: url('/assets/img/company/company-environment-title.svg') right top / contain no-repeat;
      width: 472px;
      height: 54px;
      @media (max-width: 960px){
        left: -1 * map-get($spacers, 3);
      }
      @include media-down(lg) {
        left: auto;
        right: -10px;
        bottom: calc(100% - 10px);
        height: 30px;
      }
    }

    &:after{
      left: calc(50vw - 480px - map-get($spacers, 3));
      right: -1 * map-get($spacers, 3);
      transform-origin: right;
    }
    &.lazyhide {
      opacity: 1;
    }
    &.is-intersection{
      color: $white;
      &:before{
        opacity: 1;
        transform: translateY(0);
      }
      &:after {
        transform: scale(1,1);
      }
    }
  }

  &__subtitle {
    @include media-down(lg) {
      grid-row: 2;
      display: flex;
      justify-content: center;
      margin-bottom: 0;
    }
  }

  &__body {
    max-width: 500px;
    font-size: calc(15 / 16 * $font-size-base);
    letter-spacing: 0.12em;
    line-height: 1.9;
    font-feature-settings: "palt";
    @include media-down(lg) {
      grid-row: 3;
      line-height: 1.8;
    }
    .title {
      font-size: calc(20 / 16 * $font-size-base);
      letter-spacing: 0.14em;
      line-height: 1.5;
      margin-bottom: 1.8em;
      font-weight: $font-weight-bold;
    }
    .list {
      list-style: square;
      margin-bottom: 2.5em;
      padding-left: 1em;
    }
  }

  &__img {
    @include media-down(lg) {
      grid-row: 1;
      height: 187px;
    }
    img {
      @include media-up(lg) {
        height: auto;
      }
      @include media-down(lg) {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  
  &__quality &{
    &__subtitle {
      @include media-up(lg) {
        grid-column: 1;
        margin-left: 70px;
      }
      img{
        @include media-up(lg) {
          width: 56px;
        }
      }
    }
    &__body {
      @include media-up(lg) {
        grid-column: 2;
      }
    }
    &__img {
      flex-shrink: 0;
      @include media-up(lg) {
        grid-column: 3;
      }
      @media (max-width: 1180px) {
        margin-right: -1 * map-get($spacers, 3);
      }
      img{
        display: block;
        max-width: 100%;
      }
    }
  }
  
  &__environment &{
    &__subtitle {
      @include media-up(lg) {
        grid-column: 2;
      }
      img{
        @include media-up(lg) {
          width: 56px;
        }
      }
    }
    &__body {
      @include media-up(lg) {
        grid-column: 3;
      }
    }
    &__img {
      flex-shrink: 0;
      @include media-up(lg) {
        grid-row: 1;
        grid-column: 1;
        align-self: center;
        margin-left: -100px;
      }
      @media (max-width: 1180px) {
        margin-left: -1 * map-get($spacers, 3);
      }
      img{
        display: block;
        max-width: 100%;
      }
    }
  }



}

.company-plan {
  margin-bottom: map-get($spacers, 5);
  padding: 0 map-get($spacers, 3);
  &__inner {
    max-width: 960px;
    margin: 0 auto;
  }

  &__title {
    @include c-section-title;
    text-align: center;
  }
}

// 企業情報
.company-outline {
  padding: map-get($spacers, 7) map-get($spacers, 3) 1px;
  margin-bottom: map-get($spacers, 7);
  background-color: #f6f8fa;
  @include media-down(lg) {
    padding-top: map-get($spacers, 4);
    margin-bottom: map-get($spacers, 6);
  }

  &__title {
    @include c-section-title;
  }

  &__inner {
    max-width: 960px;
    margin: 0 auto;
  }

  &__photo {
    margin-bottom: map-get($spacers, 5);
    img{
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  &__table {
    @include table-softpren-typeA;
  }

  &__map {
    margin-bottom: map-get($spacers, 6);
    &-inner {
      overflow: hidden;
      height: 450px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: map-get($spacers, 3);
    }
    iframe {
      height: calc(100% + 400px);
    }
  }
  
  &__btnwrap {
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      column-gap: 0.8em;
      font-size: calc(11 / 16 * $font-size-base);
      font-family: 'Jost', $font-family-base;
      letter-spacing: 0.16em;
      background-color: $white;
      width: 150px;
      height: 3em;
      &:hover,&:focus{
        background-color: $light;
      }
    }
  }

  &__baselist {
    display: flex;
    justify-content: center;
    column-gap: 37px;
    row-gap: map-get($spacers, 4);
    margin-bottom: map-get($spacers, 6);
    @include media-down(lg) {
      flex-direction: column;
    }
  }

  &__base {
    flex-shrink: 0;
    font-feature-settings: "palt";
    @include media-up(lg) {
      max-width: 461px;
    }
    &-photo {
      .img {
        display: block;
        max-width: 100%;
        height: auto;
      }
      .area {
        text-align: center;
        font-size: calc(17 / 16 * $font-size-base);
        letter-spacing: 0.18em;
        text-indent: 0.18em;
        margin-bottom: 0.6em;
        color: #4E638A;
        font-weight: $font-weight-bold;
      }
    }
    &-body {
      text-align: center;
      .name {
        font-size: calc(19 / 16 * $font-size-base);
        font-weight: $font-weight-bold;
        letter-spacing: 0.18em;
        color: #4E638A;
        margin-bottom: 0.5em;
      }
      .addr {
        font-size: calc(13 / 16 * $font-size-base);
        letter-spacing: 0.1em;
        line-height: 1.8;

      }
    }
    .btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      column-gap: 0.8em;
      font-size: calc(11 / 16 * $font-size-base);
      font-family: 'Jost', $font-family-base;
      letter-spacing: 0.16em;
      background-color: $white;
      width: 150px;
      height: 3em;
      &:hover,&:focus{
        background-color: $light;
      }
    }
  }

  &__slider {
    margin-left: -1 * map-get($spacers, 3);
    margin-right: -1 * map-get($spacers, 3);
    margin-bottom: map-get($spacers, 6);
    overflow: hidden;
    &-inner {
      display: flex;
      align-items: center;
      column-gap: 15px;
      animation: slider 50s linear infinite;
      @include media-down(lg) {
        column-gap: 10px;
        animation: slider_sp 50s linear infinite;
      }
    }
    img {
      @include media-down(lg) {
        max-width: 2021px;
        height: auto;
      }
    }
  }

}

.company-group {
  padding: 0 map-get($spacers, 3);
  margin-bottom: map-get($spacers, 7);
  @include media-down(lg) {
    margin-bottom: map-get($spacers, 5);
  }
  &__inner {
    max-width: 960px;
    margin: 0 auto;
  }
  &__title {
    @include c-section-title;
  }

  &__list {
    margin-top: -1 * map-get($spacers, 5);
  }

  &__item {
    padding: map-get($spacers, 5) 0;
    border-top: 1px solid #E9E9E9;
    display: flex;
    column-gap: map-get($spacers, 4);
    row-gap: map-get($spacers, 2);
    &:first-child {
      border-top-width: 0;
    }
    @include media-down(lg) {
      flex-direction: column;
    }
    &-img{
      flex-shrink: 0;
      img {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 0 auto;
      }
    }
  }

  &__body {
    font-feature-settings: "palt";
    .name {
      font-size: calc(22 / 16 * $font-size-base);
      letter-spacing: 0.18em;
      color: #4E638A;
      font-weight: $font-weight-bold;
      margin-bottom: 1em;
      @include media-down(lg) {
        margin-bottom: 0.6em;
      }
    }
    .desc {
      font-weight: $font-weight-bold;
      font-size: calc(13 / 16 * $font-size-base);
      line-height: 2;
      letter-spacing: 0.15em;
    }
    .outline {
      font-size: calc(13 / 16 * $font-size-base);
      letter-spacing: 0.15em;
      line-height: 2;
      margin-bottom: 2em;
      th,td {
        font-weight: $font-weight-normal;
      }
      th {
        padding-right: 2em;
      }
    }

    .btnwrap {
      display: flex;
      column-gap: 1em;
      row-gap: 1em;
    }
    .btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      column-gap: 0.8em;
      font-size: calc(11 / 16 * $font-size-base);
      font-family: 'Jost', $font-family-base;
      letter-spacing: 0.16em;
      width: 150px;
      height: 3em;
    }
    .btn-outline-light {
      background-color: $white;
      &:hover,&:focus{
        background-color: $light;
      }
    }

  }
}

.company-history {
  background-color: #F6F8FA;
  padding: 0 map-get($spacers, 3) map-get($spacers, 6);
  @include media-down(lg) {
    padding-bottom: map-get($spacers, 5);
  }
  &__headimg {
    margin-bottom: map-get($spacers, 6);
    margin-left: -1 * map-get($spacers, 3);
    margin-right: -1 * map-get($spacers, 3);
    @include media-up(xl) {
      height: 595px;
      background: url('/assets/img/company/history_pc@2x.jpg') center / cover fixed no-repeat;
    }
    @include media-down(lg) {
      margin-bottom: map-get($spacers, 4);
    }
    .img {
      @include media-up(xl) {
        opacity: 0;
        height: 100%;
      }
    }
  }

  &__inner {
    max-width: 960px;
    margin: 0 auto;
  }

  &__title {
    @include c-section-title;
  }

  &__timeline {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: map-get($spacers, 3);
    font-feature-settings: "palt";
    z-index: 0;
    &:after {
      content: '';
      position: absolute;
      top: 0.7em;
      left: 100px;
      bottom: 0;
      width: 1px;
      background-color: #E1E1E1;
      z-index: -1;
      @include media-down(lg) {
        left: 0;
      }
    }
  }

  &__time {
    position: relative;
    display: flex;
    column-gap: map-get($spacers, 3);
    row-gap: 0.5em;
    align-items: flex-start;
    margin: 0;
    @include media-down(lg) {
      flex-direction: column;
      padding-left: 20px;
    }
    &:last-child:before{
      position: absolute;
      content: '';
      top: calc(0.7em + 7px);
      left: 100px;
      bottom: 0;
      width: 1px;
      height: auto;
      background-color: #f6f8fa;
    }
  }
  &__year {
    position: relative;
    margin: 0;
    display: grid;
    grid-template-columns: 140px auto;
    align-items: center;
    @include media-up(lg) {
      width: 185px;
    }
    @include media-down(lg) {
      grid-template-columns: 75px auto;
    }
    &:after {
      position: absolute;
      top: 0.7em;
      left: 100.5px;
      content: '';
      width: 7px;
      height: 7px;
      background-color: #E1E1E1;
      border-radius: 100%;
      transform: translateX(-50%);
      @include media-down(lg) {
        left: -19.5px;
      }
    }
  }
  .year {
    font-size: min(5.6vw, calc(23 / 16 * $font-size-base));
    font-family: 'Jost', $font-family-base;
    letter-spacing: 0.12em;
    color: $primary;
  }
  .month {
    font-size: min(4vw, calc(17 / 16 * $font-size-base));
    font-weight: $font-weight-bold;
    color: #373737;
  }
  &__summary {
    margin: 0;
    font-size: min(3.46vw, calc(15 / 16 * $font-size-base));
    line-height: 1.8;
    @include media-up(lg) {
      letter-spacing: 0.15em;
      margin-top: 0.2em;
    }
  }
}