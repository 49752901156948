@keyframes active_btn_line {
  0% {
    height: 100px;
    // opacity: 0;
  }
  30% {
    height: 100px;
    // opacity: 1;
  }
  60% {
    height: 7px;
  }
  100% {
    height: 100px;
  }
}

.is--home {
  // .rwd002-header {
  //   box-shadow: none;
  //   @include media-up(lg) {
  //     position: absolute;
  //     width: 100%;
  //     top: 0;
  //     left: 0;
  //     z-index: 2;
  //   }
  // }

  // .rwd002-gnav {
  //   @include media-up(lg) {
  //     color: $white;
  //   }
  // }
  
  #allbox {
    padding-top: 0;
  }

  .rwd002-container {
    margin-top: 0;
  }

  .rwd002-footer{
    background-color: fade-out(#071B52, 0.15);
  }
}

//mainvisual
.mbYTP_wrapper {
  position: fixed !important;
  height: 100vh;
  height: 100dvh; //Safari用
  z-index: -1 !important;

  opacity: 1 !important;
}

.mainvisual {
  position: relative;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // background-color: #555;
  height: 100vh;
  height: 100svh; //Safari用
  margin: 0;
  @include media-down(lg) {
    height: calc(100vh - 85px);
    height: calc(100svh - 85px); //Safari用
  }

  &__catch {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    margin: auto;
    padding: map-get($spacers, 3);
    color: $white;

    @include media-up(xl) {
      width: 100%;
    }
    @include media-up(lg) {
      padding-bottom: 15vh;
    }

  }

  &__title {
    display: flex;
    flex-direction: column;
    font-size: min(4vw, calc(17 / 16 * $font-size-base));
    font-feature-settings: "palt";
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    strong {
      font-size: min(10vw, calc(55 / 16 * $font-size-base));
      font-family: 'League Spartan', $font-family-base;
      letter-spacing: 0.14em;
      font-weight: $font-weight-light;
    }
    span {
      margin-top: 1em;
      font-weight: $font-weight-bold;
      letter-spacing: 0.16em;
      line-height: 1.9;
    }
  }

  &__scroll {
    position: absolute;
    top: 50%;
    right: 0;
    width: 60px;
    height: 220px;
    margin: auto;
    border-width: 0;
    font-family: 'Roboto Condensed', $font-family-sans-serif;
    color: $white;
    font-size: $font-size-xs;
    letter-spacing: .2em;
    transform: translateY(-50%);

    @include media-down(lg) {
      display: none;
    }
    
    &:hover,
    &:focus {
      color: $white;
      text-decoration: none;
    }

    > span {
      display: block;
      transform: rotate(90deg) translateY(5%) translateX(85%);
    }

    .is-scroll-line {
      position: absolute;
      top: auto;
      bottom: 2em;
      left: 0;
      right: 0;
      display: block;
      width: 1px;
      height: 107px;
      margin: auto;
      background: rgba($white, 60%);
      z-index: 1;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        width: 1px;
        height: 6px;
        background: $primary;
        animation: active_btn_line 2s infinite ease-in-out;
      }
    }
  }
}

.mainvisual-ticker {
  display: flex;
  align-items: stretch;
  width: 100%;
  margin-left: auto;
  background-color: $white;
  @include media-up(lg) {
    max-width: 560px;
    width: 39.8vw;
  }

  @include media-down(lg) {
    max-width: calc(100% - map-get($spacers, 3));
    margin-left: map-get($spacers, 3);
  }

  &__title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    padding: .5em 1.2em;
    a {
      transition: $transition-base;
      &:hover {
        text-decoration: none;
        small {
          color: $primary;
        }
      }
    }
    strong, small {
      display: block;
      line-height: 1;
    }
    strong {
      color: $primary;
      font-family: 'League Spartan', $font-family-base;
      font-size: calc(23 / 16 * $font-size-base);
      font-weight: $font-weight-medium;
      letter-spacing: 0.12em;
    }
    small {
      font-size: 12px;
      margin-top: 3px;
      white-space: nowrap;
    }
    @include media-down(lg) {
      padding: 0 0 0 1em;
      strong {
        font-size: calc(17 / 16 * $font-size-base);
      }
      small {
        font-size: 11px;
      }
    }
  }

  @include media-up(lg) {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
  }

  &__body{
    flex-grow: 1;
    min-width: 0;
    padding: 1.2em 1.5em 1.2em 0;
    @include media-down(lg) {
      // padding: map-get($spacers, 2) map-get($spacers, 3);
      padding: .8rem 1.5rem;
    }
  }

  &__link{
    min-width: 0;
    color: inherit;
    display: block;
    &:hover,&:focus{
      text-decoration: none;
      .title{
        text-decoration: underline;
      }
    }
  }

  .cate {
    position: relative;
    display: inline-block;
    margin-right: 0.5em;
    padding-right: 0.5em;
    font-size: calc(13 / 16 * $font-size-base);
    letter-spacing: 0.06em;
    &:after{
      position: absolute;
      content: '';
      top: calc(50% - 0.5em);
      bottom: 0;
      right: 0;
      width: 0;
      height: 1em;
      border-left: 1px solid;
    }
  }

  .date{
    display: inline-block;
    font-family: 'Roboto', $font-family-base;
    // color: #868686;
    font-size: calc(13 / 16 * $font-size-base);
    letter-spacing: 0.06em;
  }

  .title{
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.25em 0;
    font-weight: $font-weight-normal;
    font-size: calc(14 / 16 * $font-size-base);
    letter-spacing: 0.06em;
    @include media-down(lg) {
      padding: 0;
    }
    span{
      @extend .text-truncate;
      display: block;
      white-space: nowrap;
    }
    &:after{
      flex-shrink: 0;
      content: '';
      background: svgUrlFunc('<svg viewBox="0 0 12.33 4.29" xmlns="http://www.w3.org/2000/svg"><path d="m.55 3.74h11.23l-2.55-3.19" fill="none" stroke="#D8D8D8" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.11"/></svg>') center / contain no-repeat;
      width: 12px;
      height: 5px;
      margin-left: 0.3em;
    }
  }

}

// block-about
.block-about {
  background-color: fade-out(#071B52, 0.15);
  color: $white;
  padding: 0 map-get($spacers, 3);

  &__inner {
    display: flex;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
    padding: map-get($spacers, 6) 0;
    column-gap: 85px;
    row-gap: map-get($spacers, 3);
    @include media-up(lg) {
      min-height: 850px;
    }
    @include media-down(lg) {
      flex-direction: column;
    }
  }

  &__body {
    flex-grow: 1;
    font-feature-settings: "palt";
    font-size: min(4vw, $font-size-base);
    letter-spacing: 0.12em;
    line-height: 2.25;
    font-weight: $font-weight-bold;
  }

  &__title {
    @include c-block-title($white);
  }

  &__lead {
    font-size: min(6.6vw, calc(48 / 16 * $font-size-base));
    letter-spacing: 0.1em;
    line-height: 1.6;
    font-weight: $font-weight-bold;
    margin-bottom: 0.5em;
  }

  &__banners {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
    width: 100%;
  }

  .banner {
    overflow: hidden;
    position: relative;
    &:hover .banner-img{
      transform: scale(1.05);
    }
    &-img {
      object-fit: cover;
      transition: $transition-base;
      @include media-down(md) {
        max-width: 100%;
      }
      
    }
    &-body {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      position: absolute;
      padding: 1em map-get($spacers, 2);
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      font-size: min(4.53vw, calc(22 / 16 * $font-size-base));
      font-feature-settings: "palt";
      @include media-up(lg) {
        padding-left: map-get($spacers, 3);
      }
    }
    .title {
      font-weight: $font-weight-bold;
      letter-spacing: 0.18em;
      @include media-down(lg) {
        padding-right: 1.5em;
      }
    }
    .readmore {
      display: flex;
      align-items: center;
      font-size: $font-size-sm;
      font-family: 'Roboto Condensed', $font-family-base;
      letter-spacing: 0.24em;
      @include media-down(lg) {
        position: absolute;
        bottom: calc(map-get($spacers, 2) + 0.2em);
        right: map-get($spacers, 2);
        span{
          display: none;
        }
      }
      &:after {
        content: '';
        border: 1px solid ;
        border-radius: 100%;
        background: svgUrlFunc('<svg viewBox="0 0 12.33 4.29" xmlns="http://www.w3.org/2000/svg"><path d="m.55 3.74h11.23l-2.55-3.19" fill="none" stroke="#{$white}" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.11"/></svg>') center / 12px no-repeat;
        height: 35px;
        width: 35px;
        aspect-ratio: 1;
        margin-left: 1em;
        @include media-down(lg) {
          height: 23px;
          width: 23px;
          background-size: 9px;
        }
      }
    }
  }
}

.block-technology {
  position: relative;
  background-color: $white;
  z-index: 0;
  &__inner {
    padding-left: calc(50vw - 550px);
    padding-top: map-get($spacers, 7);
    padding-bottom: map-get($spacers, 7);
    @media (max-width: 1130px) {
      padding-left: map-get($spacers, 3);
    }
    @include media-up(lg) {
      display: grid;
      grid-template: 'title btn' auto 'body body' auto / min(60vw,870px) 1fr;
    }
    @include media-down(lg) {
      padding-top: map-get($spacers, 5);
      padding-bottom: map-get($spacers, 5);
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
    }
    &:before {
      bottom: 30%;
      left: 0;
      width: 0;
      background-color: $primary;
      z-index: -1;
      transition: width 0.5s 0.3s ease-in-out;
      @include media-down(lg) {
        width: 80vw;
        bottom: 60%;
      }
    }
    &.is-intersection:before {
      width: min(100vw, calc((50vw - 550px) + 350px));
      @include media-down(lg) {
        width: 80%;
      }
    }
    &:after {
      bottom: 0;
      right: 0;
      width: min(100vw, calc(100vw - (50vw - 550px) + 75px));
      background-color: #F6F8FA;
      z-index: -2;
    }
  }
  &__head {
    grid-area: title;
  }
  &__title {
    @include c-block-title();
    transition: $transition-base 0.5s;
    .is-intersection &{
      color: $white;
    }
  }

  &__body {
    position: relative;
    grid-area: body;
    max-width: 100%;
  }

  &__sliderNav {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 100%;
    width: 100%;
    max-width: 1100px;
    column-gap: 22px;
    padding-top: map-get($spacers, 3);
    @include media-down(lg) {
      position: static;
      column-gap: 1em;
      padding-right: map-get($spacers, 3);
    }
  }
  .swiper-pagination-progressbar,
  .swiper-button-prev,
  .swiper-button-next {
    position: static;
    margin: 0;
    flex-shrink: 0;
    width: auto;
  }
  .swiper-pagination-progressbar {
    flex-grow: 1;
    height: 1px;
    margin-right: 1.5em;
    @include media-down(lg) {
      margin-right: 1em;
    }
  }
  .swiper-button-prev:after,
  .swiper-button-next:after{
    width: 38px;
    height: 38px;
  }

  .swiper-button-prev:after{
    content: '';
    background: svgUrlFunc('<svg viewBox="0 0 37.83 37.83" xmlns="http://www.w3.org/2000/svg"><g fill="#{$body-color}"><path d="m18.92 37.83c-10.43 0-18.92-8.48-18.92-18.91s8.49-18.92 18.92-18.92 18.92 8.49 18.92 18.92-8.49 18.92-18.92 18.92zm0-36.83c-9.88 0-17.92 8.04-17.92 17.92s8.04 17.92 17.92 17.92 17.92-8.04 17.92-17.92-8.05-17.92-17.92-17.92z"/><path d="m19.65 22.56-4.48-3.9 4.48-3.91.66.75-3.62 3.16 3.62 3.15z"/></g></svg>') center / contain no-repeat;
  }
  .swiper-button-next:after{
    content: '';
    background: svgUrlFunc('<svg viewBox="0 0 37.83 37.83" xmlns="http://www.w3.org/2000/svg"><g fill="#{$body-color}"><path d="m18.92 37.83c-10.43 0-18.92-8.48-18.92-18.91s8.49-18.92 18.92-18.92 18.92 8.49 18.92 18.92-8.49 18.92-18.92 18.92zm0-36.83c-9.88 0-17.92 8.04-17.92 17.92s8.04 17.92 17.92 17.92 17.92-8.04 17.92-17.92-8.04-17.92-17.92-17.92z"/><path d="m18.18 23.09-.66-.76 3.62-3.15-3.62-3.16.66-.75 4.48 3.91z"/></g></svg>') center / contain no-repeat;
  }

  &__item{
    position: relative;
    width: min( calc(100vw - (map-get($spacers, 3) * 2) ),418px);
    z-index: 0;
    &:after {
      position: absolute;
      content: '';
      top: 50%;
      bottom: 0;
      left: 0;
      width: 100%;
      background: linear-gradient(to bottom, transparent 0%, fade-out($secondary, 0.2) 50%);
      background-size: 100% 200%;
      z-index: 1;
      transition: $transition-base;
    }
    &:hover:after{
      top: 0;
      background-position: center 100%;
    }
  }
  &__item-img{
    display: block;
    max-width: 100%;
    height: auto;
  }
  &__item-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    bottom: 0;
    height: 4.7em;
    width: 100%;
    color: $white;
    z-index: 2;
    padding: map-get($spacers, 3);
    transition: $transition-base;
    .title {
      font-size: min(4.53vw, calc(22 / 16 * $font-size-base));
      font-weight: $font-weight-bold;
      letter-spacing: 0.18em;
    }
    .desc{
      transition: $transition-base;
      transform: translateY(1em);
      font-size: $font-size-sm;
      height: 0;
      overflow: hidden;
      line-height: 1.85;
      letter-spacing: 0.12em;
      opacity: 0;
    }
  }
  &__item:hover &__item-body{
    height: 100%;
  }
  &__item:hover .desc{
    height: auto;
    opacity: 1;
    transform: translateY(0);
    margin-top: 0.8em;
  }

  &__btnwrap {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    grid-area: btn;
    padding: 0 map-get($spacers, 3);
    @include media-down(lg) {
      margin-top: map-get($spacers, 3);
      padding-left: 0;
      justify-content: center;
    }
    .btn{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 216px;
      height: 56px;
      font-size: calc(14 / 16 * $font-size-base);
      font-family: 'League Spartan', $font-family-base;
      font-weight: $font-weight-medium;
      letter-spacing: 0.12em;
      column-gap: 1em;
      &:after{
        background-image: svg-right-arrow($white);
      }
      &:hover:after,&:focus:after{
        background-image: svg-right-arrow($white);
      }
      &:hover,&:focus{
        background-color: $secondary;
        border-color: $secondary;
      }
    }
  }
}

.block-materials {
  background-color: $white;
  padding: map-get($spacers, 7) map-get($spacers, 3);
  @include media-down(lg) {
    padding-top:    map-get($spacers, 5);
    padding-bottom: map-get($spacers, 5);
  }

  &__inner {
    max-width: 1100px;
    margin: 0 auto;
    @include media-up(lg) {
      display: grid;
      grid-template: 'title lead' auto 'list list' auto 'btn btn' auto / auto 1fr;
      column-gap: map-get($spacers, 5);
    }
  }
  &__title {
    grid-area: title;
    @include c-block-title();
    @include media-up(lg) {
      margin-bottom: 1.7em;
    }
  }

  &__lead {
    grid-area: lead;
    font-size: calc(15 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    line-height: 2;
    letter-spacing: 0.12em;
    font-feature-settings: "palt";
    @include media-up(lg) {
      margin-top: 0.5em;
    }
    @include media-down(lg) {
      margin-bottom: 1.5em;
    }
  }

  &__list {
    @include make-row(53px);
    --#{$variable-prefix}gutter-y: 35px;
    @include media-down(lg) {
      --#{$variable-prefix}gutter-y: #{map-get($spacers, 3)};
      --#{$variable-prefix}gutter-x: #{map-get($spacers, 2)};
    }
    grid-area: list;
  }

  &__item {
    @include make-col-ready();
    @include make-col(3);
    @include media-down(lg) {
      @include make-col(6);
    }
    text-underline-offset: 0.2em;
    &:hover &-img img,&:focus &-img img{
      transform: scale(1.1);
    }
  }

  &__item-img {
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    z-index: 0;
    img {
      object-fit: cover;
      transition: $transition-base;
      height: auto;
      width: 100%;
    }
  }

  &__item-body {
    font-size: min(3.73vw, calc(17 / 16 * $font-size-base));
    font-weight: $font-weight-bold;
    letter-spacing: 0.18em;
    text-indent: 0.18em;
    text-align: center;
    margin-top: 1em;
    font-feature-settings: "palt";
    color: #2e4965;
    line-height: 1.7;
  }

  &__btnwrap {
    grid-area: btn;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: map-get($spacers, 5);
    @include media-down(lg) {
      margin-top: map-get($spacers, 4);
    }
    .btn{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 216px;
      height: 56px;
      font-size: calc(14 / 16 * $font-size-base);
      font-family: 'League Spartan', $font-family-base;
      font-weight: $font-weight-medium;
      letter-spacing: 0.12em;
      column-gap: 1em;
      &:after{
        background-image: svg-right-arrow($white);
      }
      &:hover:after,&:focus:after{
        background-image: svg-right-arrow($white);
      }
      &:hover,&:focus{
        background-color: $secondary;
        border-color: $secondary;
      }
    }
  }
}

.block-company {
  background-color: $white;
  &__inner {
    position: relative;
    padding: map-get($spacers, 6) map-get($spacers, 3) 0;
    z-index: 0;
    @include media-down(lg) {
      padding-top: map-get($spacers, 5);
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 30%;
      right: 0;
      width: 0;
      background-color: $primary;
      z-index: -1;
      transition: width 0.5s 0.3s ease-in-out;
    }
    &.is-intersection:after{
      width: calc(100vw - 150px);
      @include media-down(lg) {
        width: calc(100vw - 70px);
        height: 230px;
      }
    }
  }
  &__title {
    @include c-block-title();
    align-items: center;
    transition: $transition-base 0.5s;
    .is-intersection &{
      color: $white;
    }
  }

  &__banners {
    display: flex;
    max-width: calc(100vw - 150px + map-get($spacers, 3));
    margin-left: -1 * map-get($spacers, 3);
    color: $white;
    row-gap: map-get($spacers, 3);
    @include media-down(lg) {
      padding: 0;
      margin: 0;
      max-width: 100%;
      flex-direction: column;
    }
  }
  .banner {
    flex-grow: 1;
    overflow: hidden;
    position: relative;
    &:hover .banner-img{
      transform: scale(1.05);
    }
    &-img {
      width: 100%;
      height: auto;
      transition: $transition-base;
      @include media-down(md) {
        max-width: 100%;
      }
      
    }
    &-body {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      position: absolute;
      padding: 1em map-get($spacers, 2);
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      font-size: min(4.53vw, calc(22 / 16 * $font-size-base));
      font-feature-settings: "palt";
      @include media-up(lg) {
        padding-left: map-get($spacers, 3);
      }
    }
    .title {
      font-weight: $font-weight-bold;
      letter-spacing: 0.18em;
      @include media-down(lg) {
        padding-right: 1.5em;
      }
    }
    .readmore {
      display: flex;
      align-items: center;
      font-size: $font-size-sm;
      font-family: 'Roboto Condensed', $font-family-base;
      letter-spacing: 0.24em;
      @include media-down(lg) {
        position: absolute;
        bottom: calc(map-get($spacers, 2) + 0.2em);
        right: map-get($spacers, 2);
        span{
          display: none;
        }
      }
      &:after {
        content: '';
        border: 1px solid ;
        border-radius: 100%;
        background: svgUrlFunc('<svg viewBox="0 0 12.33 4.29" xmlns="http://www.w3.org/2000/svg"><path d="m.55 3.74h11.23l-2.55-3.19" fill="none" stroke="#{$white}" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.11"/></svg>') center / 12px no-repeat;
        height: 35px;
        width: 35px;
        aspect-ratio: 1;
        margin-left: 1em;
        @include media-down(lg) {
          height: 23px;
          width: 23px;
          background-size: 9px;
        }
      }
    }
  }
}

.block-news {
  background-color: $white;
  padding: map-get($spacers, 6) map-get($spacers, 3);
  @include media-down(lg) {
    padding: map-get($spacers, 5) map-get($spacers, 3);
  }
  &__inner {
    max-width: 1100px;
    margin: 0 auto;
    @include media-up(lg) {
      display: grid;
      grid-template: 'title list' auto 'cate list' 1fr 'btn btn' auto / min(30vw, 270px) 1fr;
    }
  }

  &__title {
    grid-area: title;
    @include c-block-title();
    @include media-down(lg) {
      align-items: center;
    }
  }

  &__list {
    grid-area: list;
    @include media-up(lg) {
      display: flex;
      flex-direction: column;
    }
    @include media-down(lg) {
      @include make-row( map-get($spacers, 2) );
      --#{$variable-prefix}gutter-y: #{map-get($spacers, 3)};
    }
  }

  &__item {
    text-underline-offset: 0.2em;
    @include media-up(lg) {
      padding: 1em 0;
      display: flex;
      align-items: center;
      column-gap: 50px;
    }
    @include media-down(lg) {
      @include make-col-ready();
      @include make-col(6);
    }
    &:hover,&:focus{
      text-decoration: none;
    }
    &:hover .title ,&:focus .title{
      text-decoration: underline;
    }
    &:hover &-img img{
      transform: scale(1.1);
    }
  }
  &__item + &__item {
    @include media-up(lg) {
      border-top: 1px solid #EEEEEE;
    }
  }
  
  &__item-img {
    overflow: hidden;
    border-radius: 3px;
    overflow: hidden;
    @include media-down(lg) {
      margin-bottom: 0.8em;
    }
    img{
      display: block;
      height: auto;
      object-fit: cover;
      aspect-ratio: 1;
      max-width: 100%;
      width: 100%;
      transition: $transition-base;
      @include media-up(lg) {
        width: 140px;
        height: 140px;
      }
    }
  }

  &__item-body {
    .cate,.date{
      font-size: min(2.9vw, calc(13 / 16 * $font-size-base));
      display: inline-block;
      @include media-down(lg) {
        display: block;
      }
    }
    .cate{
      position: relative;
      letter-spacing: 0.14em;
      @include media-up(lg) {
        padding-right: 0.5em;
        margin-right: 0.5em;
        &:after{
          position: absolute;
          content: '';
          top: calc(50% - 0.5em);
          bottom: 0;
          right: 0;
          width: 0;
          height: 1em;
          border-left: 1px solid;
        }
      }
    }
    .date{
      letter-spacing: 0.14em;
    }
    .title {
      display: block;
      font-size: min(3.73vw, calc(20 / 16 * $font-size-base));
      font-weight: $font-weight-bold;
      margin-top: 0.5em;
    }
  }

  &__categories {
    grid-area: cate;
    font-size: calc(14 / 16 * $font-size-base);
    letter-spacing: 0.18em;
    @include media-down(lg) {
      margin-top: map-get($spacers, 3);
      padding-top:  map-get($spacers, 3);
      border-top: 1px solid #eee;
    }
    &-list{
      list-style: none;
      padding: 0;
      @include media-down(lg) {
        column-count: 3;
      }
    }
    &-item{
      a{
        position: relative;
        display: block;
        padding: 0.3em 0 0.3em 20px;
        text-underline-offset: 0.2em;
        &:hover:after{
          box-shadow: 0 0 0 5px fade-out($primary, 0.8);
        }
        &:after{
          content: '';
          position: absolute;
          top: 0.9em;
          left: 0;
          height: 6px;
          width: 6px;
          background-color: $primary;
          border-radius: 100%;
          transition: $transition-base;
        }
      }
    }
  }

  &__btnwrap {
    grid-area: btn;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: map-get($spacers, 3);
    @include media-up(lg) {
      justify-content: flex-end;
      margin-top: map-get($spacers, 2);
    }
    .btn {
      font-size: calc(15 / 16 * $font-size-base);
      font-family: 'League Spartan', $font-family-base;
      letter-spacing: 0.1em;
      text-underline-offset: .5em;
      text-decoration: underline;
      &:hover,&:focus{
        color: $red;
      }
      
    }
    .fa-list {
      display: inline-block;
      margin-left: 0.5em;
    }
  }
}

.block-recruit {
  background-color: $white;

  &__inner {

  }

  .banner {
    display: block;
    position: relative;
    z-index: 0;
  }
  .banner-img{
    transition: $transition-base; 
    &.is-active {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }
  .banner:hover .banner-img.is-normal {
    opacity: 0;
  }
  .banner-body {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    img{
      display: block;
      width: 100%;
      @media (min-width: 900px){
        width: auto;
        max-height: 40vw;
      }
    }
  }
}