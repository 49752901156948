@keyframes up_down_line {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
}

.rwd002-footerSocial {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
  margin-bottom: 90px;
  @include media-up(lg) {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  &__title{
    letter-spacing: 0.16em;
    margin-right: 1em;
    font-family: 'Lato', $font-family-sans-serif;
  }
  &__btn{
    @extend .btn;
    font-size: 1.8em;
    margin: 0 0.1em;
    line-height: 1;
    transition: $transition-base;
  }
  &__btn.is-fb{
    &:hover,&:focus{
      color: $sns-facebook-bg;
    }
  }
  &__btn.is-tw{
    &:hover,&:focus{
      color: $sns-twitter-bg;
    }
  }
  &__btn.is-line{
    &:hover,&:focus{
      color: $sns-line-bg;
    }
  }
}

.rwd002-pagetop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 100px;
  font-size: $font-size-xs;
  font-family: "Roboto", $font-family-sans-serif;
  color: $body-color;
  text-decoration: none;
  letter-spacing: 0.1em;
  transform-origin: bottom;
  background-color: $white;
  @include media-down(lg) {
    width: 100%;
    height: 88px;
  }

  span{
    display: none;
  }

  &:before {
    content: "";
    display: block;
    width: 25px;
    height: 32px;
    background: svgUrlFunc(
        '<svg viewBox="0 0 24.63 31.8" xmlns="http://www.w3.org/2000/svg"><g fill="currentColor"><path d="m6.2 24.97h-2.44v6.72h-1.35v-6.72h-2.41v-1.1h6.2z"/><path d="m15.45 27.99c0 .77-.13 1.44-.4 2.02-.26.58-.64 1.02-1.14 1.33-.49.31-1.06.46-1.7.46s-1.2-.15-1.69-.46c-.5-.31-.88-.75-1.15-1.32s-.41-1.23-.41-1.98v-.44c0-.76.13-1.44.41-2.02.27-.58.65-1.03 1.14-1.34s1.06-.46 1.69-.46 1.2.15 1.69.46.87.75 1.14 1.32.4 1.24.41 2.01v.43zm-1.36-.41c0-.87-.16-1.53-.49-1.99s-.79-.69-1.39-.69-1.05.23-1.38.69-.5 1.11-.51 1.95v.45c0 .86.17 1.52.5 1.99s.8.7 1.39.7 1.06-.23 1.39-.69c.32-.46.49-1.13.49-2.01v-.41z"/><path d="m20.14 28.79v2.9h-1.36v-7.82h2.99c.87 0 1.57.23 2.08.68.51.46.77 1.06.77 1.81s-.25 1.36-.75 1.79-1.21.64-2.11.64zm0-1.1h1.63c.48 0 .85-.11 1.11-.34.25-.23.38-.56.38-.99s-.13-.76-.39-1.01-.61-.38-1.06-.39h-1.67z"/><path d="m20.94 9.77c-.13 0-.27-.05-.37-.16l-7.68-8.37-7.68 8.37c-.19.2-.5.22-.71.03-.2-.19-.22-.5-.03-.71l8.05-8.77c.19-.21.55-.21.74 0l8.05 8.77c.19.2.17.52-.03.71-.1.09-.22.13-.34.13z"/><path d="m12.89 16.5c-.28 0-.5-.22-.5-.5v-15.5c0-.28.22-.5.5-.5s.5.22.5.5v15.5c0 .28-.22.5-.5.5z"/></g></svg>'
      )
      no-repeat center / contain;
  }
  &:hover,
  &:focus {
    text-decoration: none;
    &:before {
      animation: up_down_line 0.9s infinite ease-in-out;
    }
  }
  &__wrap {
    z-index: 10;
    transition: $transition-base;
    @include media-up(lg) {
      margin: 0 auto;
    }
    @include media-down(lg) {
      order: 0;
    }
  }
}

.rwd002-footer {
  background: url('/assets/img/footer_bg@2x.jpg') center 80% / cover no-repeat;
  color: $white;
  padding: 0 map-get($spacers, 3) map-get($spacers, 3);

  @include media-down(lg) {
    background-color: #1c2c5a;
    background-size: 1400px auto;
    background-position: center bottom;
  }

  &__inner {
    display: flex;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
    @include media-up(lg) {
      min-height: 300px;
    }
    @include media-down(lg) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__info {
    font-feature-settings: "palt";
    @include media-up(lg) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-right: map-get($spacers, 6);
    }
    @include media-down(xl) {
      padding-right: min(3vw, map-get($spacers, 6));
    }
    @include media-down(lg) {
      margin-bottom: 2em;
      text-align: center;
      align-items: center;
    }
    .opentime {
      display: block;
      font-size: calc(12 / 16 * $font-size-base);
      letter-spacing: 0.2em;
      margin-bottom: 2.5em;
    }
    .btn {
      padding-left: 2em;
      padding-right: 2em;
      font-family: Jost, $font-family-base;
      font-weight: $font-weight-medium;
      letter-spacing: 0.16em;
      font-size: calc(12 / 16 * $font-size-base);
      &:hover,&:focus{
        background-color: $secondary;
        border-color: $secondary;
      }
    }
    .fa-location-dot{
      margin-right: 0.5em;
    }
  }
  &__companyName {
    margin-bottom: map-get($spacers, 3);
    @include media-up(xl) {
      margin-left: -45px;
    }

  }

  &__address {
    font-size: calc(12 / 16 * $font-size-base);
    letter-spacing: 0.2em;
    font-feature-settings: "palt";
    margin-bottom: 0;
    .zip{
      display: block;
    }
  }


  &__copyright,
  &__login {
    display: block;
    text-align: center;
    font-size: $font-size-xxs;
    letter-spacing: 0.14em;
    text-indent: 0.14em;
    color: fade-out($white, 0.15);
    font-feature-settings: "palt";
  }

  &__copyright {
    @include media-down(md) {
      margin-left: -1.3em;
      margin-right: -1.3em;
    }
  }

  &__login {
    margin-top: 1em;
  }
}

.rwd002-footerNav {
  font-size: calc(13 / 16 * $font-size-base);
  flex-grow: 1;
  @include media-down(lg) {
    display: flex;
    flex-wrap: wrap;
  }
  @include media-down(md) {
    display: block;
  }
  &__list,
  &__sublist {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  &__list {
    @include media-up(md) {
      display: flex;
    }
    @include media-down(lg) {
      margin-right: 2.5em;
      border-top: 1px solid fade-out($white, 0.6);
    }
    @include media-down(md) {
      margin-right: 0;
      margin-left: -0.8em;
      margin-right: -0.8em;
      margin-bottom: 1.5em;
    }
  }

  &__col {
    @include media-up(lg) {
      flex-grow: 1;
    }
  }
  &__sublist {
    font-size: $font-size-xs;
    color: darken($white, 40%);
    @include media-up(lg) {
      grid-column: 2;
      grid-row: 1;
    }
    @include media-down(md) {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.rwd002-footerNav-item {
  display: flex;
  flex-wrap: wrap;
  break-inside: avoid;
  @include media-up(lg) {
    flex-direction: column;
    white-space: nowrap;
  }
  @include media-down(md) {
    position: relative;
    border: solid fade-out($white, 0.6);
    border-width: 0 0 1px;
    & + & {
      border-top-width: 0;
    }
  }
  &__link {
    position: relative;
    display: inline-block;
    padding: 0.4em 1em;
    letter-spacing: 0.18em;
    font-feature-settings: "palt";
    font-weight: $font-weight-bold;
    text-underline-offset: 0.2em;
    &:hover,
    &:focus {
      color: $white;
    }
    &:before {
      position: absolute;
      content: "";
      height: 1px;
      width: 0.3em;
      top: 1em;
      left: 0;
      background-color: darken($white, 20%);
    }
    @include media-down(md) {
      flex-grow: 1;
      flex-basis: calc(100% - 40px);
      padding: 0.55em 40px 0.55em 1.7em;
      &:before {
        top: 1.25em;
        left: 0.7em;
      }
    }
  }
}

.rwd002-footerNav-subitem {
  &__link {
    display: inline-block;
    padding: 0.3em 0;
    margin-bottom: 0.5em;
    letter-spacing: 0.16em;
    font-weight: $font-weight-bold;
    transition: $transition-base;
    &:hover,
    &:focus {
      color: $white;
    }
    @include media-down(lg) {
      margin-bottom: 0;
      margin-right: 2em;
    }
  }
}

.rwd002-footer-subnav {
  color: fade-out($white, 0.15);
  @include media-up(md) {
    display: block !important;
  }
  &__tgl {
    @extend .btn;

    &:focus {
      box-shadow: none;
    }

    @include media-up(md) {
      display: none;
    }
    @include media-down(md) {
      position: relative;
      flex-basis: 40px;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: calc(50% - 5px);
        width: 10px;
        height: 1px;
        background-color: fade-out($white, 0.6);
        transition: transform 0.2s ease-in-out;
      }

      &:after {
        transform: rotate(-90deg);
      }

      &.is-active {
        &:before {
          transform: rotate(180deg);
        }
        &:after {
          transform: rotate(0deg);
        }
      }
    }
  }
  &__list {
    list-style: none;
    padding-left: 1.7em;
    padding-bottom: 1em;
    @include media-up(lg) {
      padding-left: 0.9em;
    }
  }
}

.rwd002-footer-subnav-item {
  &__link {
    display: block;
    padding: 0.4em 0;
    text-underline-offset: 0.2em;
  }
}

.rwd002-footer-search {
  margin-top: 2em;
  @include media-down(lg) {
    width: 100%;
    margin-top: 1.5em;
    margin-bottom: 3em;
  }
  &__inner {
    position: relative;
    max-width: 320px;
    @include media-down(md) {
      margin-left: -0.8em;
      margin-right: -0.8em;
    }
  }
  &__input {
    @extend .form-control;
    padding-right: 2.2em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    background-color: tint($dark, 10%);
    transition: $transition-base;
    border-width: 0;
  }
  &__btn {
    @extend .btn;
    position: absolute;
    top: 0;
    bottom: 0;
    right: calc(100% - 2.2em);
    width: 2.2em;
    padding: 0 0.5em;
    pointer-events: none;
    transition: $transition-base;
    > svg {
      width: 1em;
      height: 100%;
      fill: tint($dark, 30%);
    }
  }
  &__btn:focus,
  &__input:focus + &__btn {
    pointer-events: auto;
    right: 0;
  }
}

.footer-head {
  position: relative;
  display: flex;
  margin-left: -1 * map-get($spacers, 3);
  margin-right: -1 * map-get($spacers, 3);
  @include media-down(lg) {
    flex-direction: column;
    margin-bottom: map-get($spacers, 5);
  }

}
.footer-contact{
  flex-grow: 1;
  padding: map-get($spacers, 5) map-get($spacers, 3);
  background-color: #F6F8FA;
  color: $body-color;
  @include media-down(lg) {
    order: 1;
    padding: map-get($spacers, 4) map-get($spacers, 3);
  }
  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 1em;
    font-size: min(6.93vw, calc(40 / 16 * $font-size-base));
    letter-spacing: 0.14em;
    text-indent: 0.14em;
    color: $primary;
    span{
      position: relative;
      font-family: 'Montserrat', $font-family-base;
      font-weight: $font-weight-medium;
      padding-bottom: 0.2em;
      &:after{
        content: '';
        position: absolute;
        top: 100%;
        left: calc(50% - 0.5em);
        width: 1em;
        height: 1px;
        background-color: currentColor;
      }
    }
    small {
      color: $body-color;
      font-size: min(3.46vw, calc(15 / 16 * $font-size-base));
      letter-spacing: 0.19em;
      text-indent: 0.19em;
      margin-top: 1.3em;
    }
  }

  &__body {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
  }

  &__tel {
    display: flex;
    flex-direction: column;
    font-size: min(7.46vw, calc(34 / 16 * $font-size-base));
    @include media-up(lg) {
      padding-left: 1.1em;
      margin: 0 1em;
    }
    @include media-down(lg) {
      text-align: center;
      margin: 0 0 0.6em;
    }
    .tel {
      display: block;
      font-family: Montserrat, $font-family-base;
      letter-spacing: 0.12em;
      @include media-up(lg) {
        text-indent: -1.1em;
        pointer-events: none;
      }
      &:before {
        content: '';
        display: inline-block;
        width: 1.1em;
        height: 0.75em;
        margin-top: 0.1em;
        background: svgUrlFunc('<svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg"><circle cx="13" cy="13" fill="#{$primary}" r="13"/><path d="m10.19 8.34 1.26 1.25c.18.18.17.49-.03.69l-.51.51c-.18.18-.21.46-.07.65 0 0 .17 1.17 1.54 2.53 1.4 1.4 2.49 1.5 2.49 1.5.19.14.46.11.65-.07l.5-.51c.2-.2.51-.21.69-.03l1.25 1.25c.18.18.17.49-.03.69 0 0-.07.07-.83.82-.87.87-4.07-.8-5.85-2.58-1.79-1.79-3.46-4.98-2.58-5.85.75-.75.82-.82.83-.82.2-.2.51-.21.69-.03z" fill="#f6f8fa"/></svg>') left center / contain no-repeat;
      }
    }
    .opentime {
      font-size: calc(12 / 16 * $font-size-base);
      letter-spacing: 0.19em;
      font-feature-settings: "palt";
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 78px;
    width: 75%;
    letter-spacing: 0.14em;
    .icon{
      margin-right: 0.5em;
    }
    &:hover,&:focus{
      background-color: $secondary;
      border-color: $secondary;
    }
    @include media-up(lg) {
      max-width: 328px;
    }
    @include media-down(lg) {
      height: 60px;
    }
  }
}