@keyframes recruit_slider {
  from {transform: translateX(0);}
  to {transform: translateX(-3850px - 15px);}
}
@keyframes recruit_slider_sp {
  from {transform: translateX(0);}
  to {transform: translateX(-2021px - 10px);}
}

.is--recruit {
  &.is--article-1 .content-header{
    padding: 0;
    height: auto;
    &__inner {
      position: relative;
      max-width: 100%;
      z-index: -1;
    }
    &__title {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
      text-align: center;
      font-family: 'Montserrat', $font-family-base;
      font-size: min(10vw, calc(142 / 16 * $font-size-base));
      color: $white;
      padding-bottom: map-get($spacers, 5);
    }
  }

  &.is--article-1 .rwd002-pagination {
    display: none;
  }
  .rwd002-detail {
    margin-top: 0;
  }
  .editor-template {
    font-size: inherit !important;
  }
}

.recruit-head {

  text-align: center;
  padding: map-get($spacers, 5) map-get($spacers, 3);
  @include media-down(lg) {
    padding: map-get($spacers, 4) map-get($spacers, 3);
  }
  .btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &:after {
      content: '';
      position: absolute;
      top: calc(50% - 0.35em);
      right: 1em;
      background: svgUrlFunc('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$secondary}" d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>') center / contain no-repeat;
      height: 0.7em;
      width: 0.7em;
    }
    &:hover:after{
      background-image: svgUrlFunc('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$white}" d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>');
    }
  }
  .btn-lg {
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 1em;
    padding-bottom: 1em;
    height: 66px;
    width: 100%;
    max-width: 388px;
    letter-spacing: 0.18em;
    @include media-down(lg) {
      font-size: min(3.73vw, calc(18 / 16 * $font-size-base));
      height: auto;
    }
  }
  .btn-outline-secondary {
    border-width: 2px;
    border-radius: 0;
    &:hover,&:focus{
      color: $white;
    }
  }

  &__lead {
    margin: map-get($spacers, 4) 0;
    font-size: calc(15 / 16 * $font-size-base);
    letter-spacing: 0.18em;
    line-height: 2;
    font-feature-settings: "palt";
    @include media-up(lg) {
      font-weight: $font-weight-bold;
    }
    @include media-down(lg) {
      text-align: left;
      line-height: 1.8;
    }
  }
  @include media-down(lg) {
    p{
      display: inline;
    }
  }
  .leadtitle {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto map-get($spacers, 4);
  }
  
}

.recruit-slider {
  overflow: hidden;
  margin-bottom: map-get($spacers, 7);
  @include media-down(lg) {
    margin-bottom: map-get($spacers, 6);
  }
  &__inner {
    display: flex;
    align-items: center;
    column-gap: 15px;
    animation: recruit_slider 50s linear infinite;
    @include media-down(lg) {
      column-gap: 10px;
      animation: recruit_slider_sp 50s linear infinite;
    }
  }
  img {
    @include media-down(lg) {
      max-width: 2021px;
      height: auto;
    }
  }
}

.recruit-aboutus {
  margin-bottom: map-get($spacers, 7);
  @include media-down(lg) {
    margin-bottom: map-get($spacers, 6);
  }
  &.lazyhide {
    opacity: 1;
  }
  &__inner {
    position: relative;
    margin-left: max( 1px, calc(50vw - 550px));
    padding: map-get($spacers, 5) 0;
    z-index: 0;
    display: flex;
    justify-content: flex-end;
    column-gap: 120px;
    row-gap: 25px;
    transition: color 0.2s 0.3s ease-in-out;
    @media (max-width: 1100px){
      margin-left: 0;
    }
    @include media-down(lg) {
      flex-direction: column;
      padding: 0 map-get($spacers, 3) map-get($spacers, 3);
    }
    .is-intersection &{
      color: $white;
    }
    &:before,&:after {
      content: '';
      position: absolute;
      transform: scale(0,1);
      transform-origin: left;
      transition: transform 0.8s ease-in-out;
      .is-intersection &{
        transform: scale(1);
      }
    }
    &:before {
      top: -30px;
      left: -30px;
      height: 267px;
      width: 267px;
      background-color: $primary;
      z-index: -1;
      @include media-down(lg) {
        display: none;
      }
    }
    &:after {
      top: 0;
      left: 0;
      right: calc(50vw - 550px);
      bottom: 0;
      background-color: #00AAC3;
      z-index: -1;
      @media (max-width: 1100px){
        right: 0;
      }
      @include media-down(lg) {
        top: 85px;
      }
    }
  }

  &__body {
    max-width: 500px;
    font-feature-settings: "palt";
    font-size: calc(15 / 16 * $font-size-base);
    line-height: 1.9;
    letter-spacing: 0.14em;
    @include media-down(lg) {
      order: 2;
    }
  }
  
  &__title {
    position: relative;
    font-family: 'Montserrat', $font-family-base;
    font-size: min( 5.33vw, calc(27 / 16 * $font-size-base));
    font-weight: $font-weight-bold;
    color: #9EFFFF;
    letter-spacing: 0.18em;
    padding-bottom: 0.4em;
    margin-bottom: 0.9em;
    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 1em;
      border-bottom: 1px solid ;
    }
  }

  &__subtitle {
    font-size: min(5.06vw, calc(26 / 16 * $font-size-base));
    font-weight: $font-weight-bold;
    letter-spacing: 0.16em;
    line-height: 1.8;
    margin-bottom: 1em;
  }

  &__img {
    @include media-up(lg) {
      margin-top: calc(-30px - map-get($spacers, 5));
    }
    @include media-down(lg) {
      order: 1;
    }
    .img{
      @include media-up(lg) {
        height: 483px;
        width: calc(50vw - 220px);
        object-fit: cover;
      }
      @include media-down(lg) {
        width: 100%;
        height: auto;
      }
    }
  }
}

.recruit-strength {
  padding: 0 map-get($spacers, 3);
  margin-bottom: map-get($spacers, 7);
  @include media-down(lg) {
    margin-bottom: map-get($spacers, 6);
  }
  &__title {
    @include c-section-title;
  }
  &__list {
    display: grid;
    grid-template: auto / 462px 462px;
    justify-content: center;
    column-gap: 34px;
    row-gap: 45px;
    @include media-down(lg) {
      grid-template: auto / auto;
      margin-left: -1 * map-get($spacers,2);
      margin-right: -1 * map-get($spacers,2);
    }

  }

  &__item{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: map-get($spacers, 4);
    background: #EEF2F6;
    border-radius: 5px;
    font-feature-settings: "palt";
    @include media-down(lg) {
      padding: map-get($spacers, 3);
    }
    .num {
      position: absolute;
      top: -10px;
      left: -10px;
      @include media-down(lg) {
        top: -5px;
        left: -5px;
      }
    }
    .title {
      font-weight: $font-weight-bold;
      font-size: calc(20 / 16 * $font-size-base);
      letter-spacing: 0.16em;
      color: #275692;
      margin-bottom: 1em;
    }
    .icon {
      margin-bottom: 1.5em;
    }
    .list {
      width: 100%;
      list-style: none;
      font-size: min(3.46vw, calc(14 / 16 * $font-size-base));
      font-weight: $font-weight-bold;
      letter-spacing: 0.1em;
      padding-left: 1em;
      &:last-child{
        margin-bottom: 0;
      }
      li{
        position: relative;
        margin-bottom: 0.7em;
        &:last-child{
          margin-bottom: 0;
        }
        &:before {
          content: "";
          position: absolute;
          top: 0.4em;
          right: calc(100% + 0.5em);
          height: 0.64em;
          width: 0.64em;
          background-color: #e0e2e2;
          border-radius: 100%;
        }
      }
    }
  }

  &__item.is-item01 {
    @include media-up(lg) {
      grid-row: 1;
    }
    @include media-down(lg) {
      grid-row: 1;
    }
  }

  &__item.is-item02 {
    @include media-up(lg) {
      grid-row: 1;
    }
    @include media-down(lg) {
      grid-row: 2;
    }
  }

  &__item.is-item03 {
    @include media-up(lg) {
      grid-row: 2;
    }
    @include media-down(lg) {
      grid-row: 3;
    }
  }

  &__item.is-item04 {
    @include media-up(lg) {
      grid-row: 2;
    }
    @include media-down(lg) {
      grid-row: 4;
    }
  }
}

.recruit-analysis {
  padding: map-get($spacers, 6) map-get($spacers, 3);
  margin-bottom: map-get($spacers, 7);
  background-color: #00AAC3;
  @include media-down(lg) {
    margin-bottom: map-get($spacers, 6);
    padding: map-get($spacers, 5) map-get($spacers, 3);
  }
  &__inner {
    max-width: 1200px;
    margin: 0 auto;
  }

  &__title {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5em;
    img{
      display: block;
      height: auto;
      @include media-up(lg) {
        width: 583px;
      }
      @include media-down(lg) {
        width: 319px;
      }
    }
  }
  
  &__grid {
    display: grid;
    gap: 7px;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: masonry;
    @include media-down(lg) {
      grid-template-columns: 50% 50%;
    }
  }

  &__data{
    background-color: $white;
    padding: map-get($spacers, 3) map-get($spacers, 2);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #0868a9;
    row-gap: 0.3em;
    column-gap: 15%;
    font-size: min(9vw, calc(47 / 16 * $font-size-base));
    font-feature-settings: "palt";
    font-family: 'Roboto', $font-family-base;
    .name {
      font-weight: $font-weight-bold;
      font-size: 0.382em;
      letter-spacing: 0.14em;
      text-indent: 0.14em;
      text-align: center;
    }
    p{
      margin: 0;
      line-height: 1.2;
    }
    small{
      font-size: 0.4em;
      font-weight: $font-weight-bold;
      letter-spacing: 0.06em;
      text-indent: 0.06em;
    }
    .icon{
      display: block;
      max-width: 100%;
      height: auto;
    }
    
  }

  &__data.is-data01 {
    grid-column: span 2;
    font-size: min( 23vw, calc(120 / 16 * $font-size-base));
    row-gap: 0.1em;
    @include media-up(lg) {
      grid-row: span 2;
      justify-content: center;
      .name {
        font-size: calc(32 / 16 * $font-size-base);
      }
    }
  }
  &__data.is-data02,
  &__data.is-data10 {
    grid-column: span 2;
    flex-direction: row;
    display: grid;
    grid-template: auto 1fr / auto ;
    align-items: center;
    justify-content: center;
    row-gap: 0em;
    @include media-down(lg) {
      row-gap: 0.5em;
    }
    .name {
      width: 100%;
      align-self: flex-start;
      grid-column: span 2;
    }
    p{
      grid-row: 2;
      grid-column: 1;
    }
    .icon {
      grid-row: 2;
      grid-column: 2;
    }
  }
  &__data.is-data11 {
    @include media-down(lg) {
      grid-row: 6;
    }
  }
}

.recruit-benefit {
  padding: 0 map-get($spacers, 3);
  margin-bottom: map-get($spacers, 6);
  @include media-down(lg) {
    margin-bottom: map-get($spacers, 5);
  }
  &__inner {
    max-width: 960px;
    margin: 0 auto;
  }

  &__title {
    @include c-section-title;
  }

  &__row {
    display: flex;
    column-gap: 21px;
    row-gap: 24px;
    justify-content: center;
    margin-bottom: map-get($spacers, 5);
    @include media-down(lg) {
      flex-direction: column;
      margin-bottom: map-get($spacers, 4);
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1em;
    margin: 0;
    text-align: center;
    @include media-up(lg) {
      max-width: 306px;
    }
  }
  .caption {
    font-feature-settings: "palt";
    font-size: calc(13 / 16 * $font-size-base);
    letter-spacing: 0.13em;
    strong {
      display: block;
      font-size: calc(18 / 16 * $font-size-base);
      font-weight: $font-weight-bold;
      letter-spacing: 0.1em;
      text-indent: 0.1em;
      color: $primary;
      margin-bottom: 0.5em;
    }
  }

  &__others {
  }
  &__other-title {
    font-size: min(5.86vw,calc(26 / 16 * $font-size-base));
    font-weight: $font-weight-bold;
    letter-spacing: 0.18em;
    color: $primary;
    padding-left: 0.8em;
    border-left: 5px solid;
    margin-bottom: 1em;
    @include media-down(lg) {
      border-left-width: 4px;
    }
  }

  &__other-list {
    padding-left: 1.1em;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: min(3.46vw, calc(14 / 16 * $font-size-base));
    font-weight: $font-weight-bold;
    @include media-up(lg) {
      column-count: 2;
      column-gap: 3em;
    }
    li{
      position: relative;
      margin: 0;
      padding-top: 0.7em;
      padding-bottom: 0.7em;
      padding-left: 1.5em;
      break-inside: avoid;
      border-bottom: 1px solid #E5E8ED;
      &:last-child{
        margin-bottom: 0;
        @include media-down(lg) {
          border-bottom-width: 0;
        }
      }
      &:before {
        content: "";
        position: absolute;
        top: 1.1em;
        left: 0;
        height: 0.64em;
        width: 0.64em;
        background-color: #e0e2e2;
        border-radius: 100%;
      }
    }
  }

}

.recruit-guideline {
  padding: map-get($spacers, 6) map-get($spacers, 3);
  margin-bottom: map-get($spacers, 7);
  background-color: #2A417D;
  @include media-down(lg) {
    padding: map-get($spacers, 5) map-get($spacers, 3);
    margin-bottom: map-get($spacers, 6);
  }

  &__inner {
    max-width: 960px;
    margin: 0 auto;
  }
  &__title {
    @include c-section-title($white,$white);
  }
  
  &__list {
    display: flex;
    flex-direction: column;
    margin-bottom: map-get($spacers, 5);
    row-gap: 12px;
    @include media-down(lg) {
      margin-left: -12px;
      margin-right: -12px;
    }
    .no-entry {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      font-size: min(4vw, calc(18 / 16 * $font-size-base));
      font-weight: $font-weight-bold;
      letter-spacing: 0.2em;
      text-indent: 0.2em;
      font-feature-settings: "palt";
      span {
        padding: 1em 2em;
        border: 2px solid;
      }
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 57px;
    row-gap: 25px;
    position: relative;
    background-color: $white;
    padding: 25px map-get($spacers, 4) 25px map-get($spacers, 3);
    border-radius: 5px;
    font-feature-settings: "palt";
    &:hover,&:focus{
      text-decoration: none;
    }
    &:hover .title,
    &:hover .desc{
      text-decoration: underline;
      text-underline-offset: 0.2em;
    }
    &:hover .img {
      transform: scale(1.1);
    }
    @include media-down(lg) {
      flex-direction: column;
      padding: map-get($spacers, 3) map-get($spacers, 2);
    }
    &:after {
      content: '';
      position: absolute;
      top: calc(50% - 6px);
      right: 1em;
      background: svgUrlFunc('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#2A417D" d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>') center / contain no-repeat;
      width: 12px;
      height: 12px;
      @include media-down(lg) {
        top: calc(100% - 25px);
      }
    }
    &-img {
      overflow: hidden;
      flex-shrink: 0;
    }
    .img{
      display: block;
      max-width: 100%;
      height: auto;
      transition: $transition-base;
      max-height: 230px;
      object-fit: cover;
    }
    .cat {
      display: inline-block;
      min-width: 10em;
      color: $white;
      background-color: $primary;
      font-size: calc(12 / 16 * $font-size-base);
      letter-spacing: 0.14em;
      text-align: center;
      padding: 0.1em 1em;
      margin-bottom: 1em;
    }
    .title {
      font-size: calc(18 / 16 * $font-size-base);
      font-weight: $font-weight-bold;
      margin-bottom: 0.5em;
      letter-spacing: 0.14em;
    }
    .is-active {
      display: inline-block;
      background-color: $danger;
      color: $white;
      font-size: calc(12 / 16 * $font-size-base);
      padding: 0.1em 1em;
      margin-left: 1em;
      border-radius: 100em;
      letter-spacing: 0.14em;
      text-indent: 0.14em;
      vertical-align: middle;
    }
    .desc {
      font-size: calc(12 / 16 * $font-size-base);
      letter-spacing: 0.2em;
    }
  }

  &__btnwrap {
    display: flex;
    align-items: center;
    justify-content: center;
    .btn-secondary {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      padding-left: 2em;
      padding-right: 2em;
      width: 100%;
      max-width: 485px;
      height: 85px;
      font-size:min(4.26vw, calc(20 / 16 * $font-size-base));
      @include media-down(lg) {
        height: 55px;
      }
      &:after {
        width: 0.8em;
        background-image: svg-right-arrow($white);
      }
    }
  }
}
.recruit-data {
  padding: 0 map-get($spacers, 3);
  margin-bottom: map-get($spacers, 6);
  &__inner {
    max-width: 960px;
    margin: 0 auto;
  }

  &__title {
    font-size: min(5.86vw,calc(26 / 16 * $font-size-base));
    font-weight: $font-weight-bold;
    letter-spacing: 0.18em;
    color: $primary;
    padding-left: 0.8em;
    border-left: 5px solid;
    margin-bottom: 1em;
    @include media-down(lg) {
      border-left-width: 4px;
    }
  }
  
  &__resultTable {
    margin-bottom: map-get($spacers, 7);
    @include media-down(lg) {
      margin-bottom: map-get($spacers, 6);
    }
    &__table {
      @include table-softpren-typeB;
    }
    &.is-scrollable {
      @include media-down(lg) {
        position: relative;
        max-width: 100%;
        padding-top: 30px;
        &:after {
          position: absolute;
          top: 0;
          right: 0;
          content: "";
          background: svgUrlFunc(
              '<svg viewBox="0 0 52.74 16.16" xmlns="http://www.w3.org/2000/svg"><g fill="#{$body-color}"><path d="m52.74 16.16h-52.74v-1h50.96l-2.54-4.19.86-.52z"/><path d="m5.44 7.32c0-.29-.11-.52-.33-.68s-.61-.3-1.15-.42-.98-.26-1.31-.42-.56-.36-.71-.59-.23-.5-.23-.81c0-.52.22-.96.66-1.32s1-.54 1.68-.54c.72 0 1.3.19 1.75.56s.67.85.67 1.42h-1.09c0-.3-.13-.55-.38-.77s-.57-.32-.95-.32-.7.09-.93.26-.33.4-.33.67c0 .26.1.46.31.59s.58.26 1.12.38.98.27 1.32.43.58.37.75.61.24.52.24.86c0 .57-.23 1.02-.68 1.36s-1.04.51-1.76.51c-.51 0-.96-.09-1.35-.27s-.7-.43-.92-.75-.33-.67-.33-1.05h1.08c.02.36.17.65.44.86s.63.32 1.08.32c.41 0 .74-.08.99-.25s.37-.39.37-.67z"/><path d="m11.92 8.23c.39 0 .72-.12 1.01-.35s.45-.53.48-.88h1.03c-.02.36-.14.71-.38 1.04s-.54.59-.92.79-.79.29-1.22.29c-.86 0-1.55-.29-2.06-.86s-.76-1.36-.76-2.36v-.18c0-.62.11-1.17.34-1.65s.55-.85.98-1.12.92-.4 1.5-.4c.71 0 1.3.21 1.77.64s.72.98.75 1.66h-1.03c-.03-.41-.19-.75-.47-1.01s-.62-.4-1.03-.4c-.55 0-.98.2-1.28.59s-.45.97-.45 1.72v.21c0 .73.15 1.29.45 1.69s.73.59 1.29.59z"/><path d="m20.16 3.63c-.16-.03-.34-.04-.53-.04-.71 0-1.19.3-1.45.91v4.5h-1.08v-6.34h1.05l.02.73c.36-.57.86-.85 1.51-.85.21 0 .37.03.48.08z"/><path d="m22.31 5.77c0-.62.12-1.18.37-1.68s.58-.88 1.02-1.15.93-.4 1.49-.4c.86 0 1.56.3 2.09.9s.8 1.39.8 2.38v.08c0 .62-.12 1.17-.35 1.66s-.57.87-1.01 1.15-.94.41-1.51.41c-.86 0-1.56-.3-2.09-.9s-.8-1.39-.8-2.37v-.08zm1.09.13c0 .7.16 1.27.49 1.69s.76.64 1.31.64.99-.22 1.31-.65.49-1.04.49-1.81c0-.7-.17-1.26-.5-1.69s-.77-.65-1.32-.65-.97.21-1.29.64-.49 1.04-.49 1.83z"/><path d="m32.06 9h-1.08v-9h1.08z"/><path d="m36.41 9h-1.08v-9h1.08z"/></g></svg>'
            )
            center / cover no-repeat;
          width: 53px;
          height: 16px;
          animation: scroll_table_icon 1s infinite ease-in-out alternate-reverse;
        }
      }
    }
    &.is-scrollable & {
      &__inner {
        @include media-down(lg) {
          overflow: auto;
        }
      }
      &__table {
        @include media-down(lg) {
          width: 800px;
        }
      }
    }
  }

  &__dataTable {
    @include table-softpren-typeA;
  }

  &__lead {
    font-size: calc(14 / 16 * $font-size-base);
    letter-spacing: 0.18em;
    display: block;
    margin-bottom: 2em;
  }

  &__btnwrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -50px;
  }
  .btn-secondary {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    padding-left: 2em;
    padding-right: 2em;
    width: 100%;
    max-width: 485px;
    height: 85px;
    font-size:min(4.26vw, calc(20 / 16 * $font-size-base));
    @include media-down(lg) {
      height: 55px;
    }
    &:after {
      width: 0.8em;
      background-image: svg-right-arrow($white);
    }
  }
}

.recruit-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: map-get($spacers, 3);
  padding: 0 map-get($spacers, 3);
  margin-bottom: map-get($spacers, 5);
  .banner {
    @include hoverOpacity;
  }
}

.recruit-movie {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: map-get($spacers, 3);
  padding: 0 map-get($spacers, 3);
  margin-bottom: map-get($spacers, 5);
  &__iframe {
    width: 800px;
    max-width: 100%;
    height: auto;
    aspect-ratio: 800/450;
  }
}


.recruit-detail-head {
  max-width: 960px;
  margin: map-get($spacers, 5) auto 0;
  @include media-down(lg) {
    margin-top: map-get($spacers, 4);
  }

  &__body {
    margin-bottom: map-get($spacers, 4);
    @include media-down(lg) {
      margin-bottom: map-get($spacers, 2);
    }
    .cat{
      display: block;
      color: $primary;
      font-size: calc(14 / 16 * $font-size-base);
      letter-spacing: 0.12em;
      font-weight: $font-weight-bold;
      @include media-up(lg) {
        margin-bottom: 0.7em;
      }
      .fa-tag {
        margin-right: 0.3em;
      }
    }
    .subject {
      position: relative;
      display: block;
      font-size: min(5.86vw,calc(26 / 16 * $font-size-base));
      font-weight: $font-weight-bold;
      letter-spacing: 0.18em;
      color: #627597;
      padding-bottom: 0.5em;
      margin-bottom: 1.5em;
      text-align: center;
      @include media-down(lg) {
        border-left-width: 4px;
      }
      &:after{
        content: '';
        position: absolute;
        top: 100%;
        left: calc(50% - 0.8em);
        border-bottom: 1px solid;
        width: 1.6em;
      }
    }
  }

  &__image {
    max-width: 100%;
    margin-bottom: map-get($spacers, 4);
    @include media-down(lg) {
      margin-bottom: map-get($spacers, 2);
    }

    .img {
      display: block;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
    }
  }

  &__keywords-list {
    display: flex;
    column-gap: 1em;
    row-gap: 0.5em ;
    list-style: none;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: map-get($spacers, 3);
    
    .tag {
      font-feature-settings: "palt";
      display: block;
      text-align: center;
      font-size: min(2.66vw, calc(14 / 16 * $font-size-base));
      letter-spacing: 0.12em;
      color: #627597;
      border: 1px solid #CAD0DC;
      border-radius: 3px;
      transition: $transition-base;
      padding: 0.3em 1em;
      @include media-up(lg) {
        min-width: 150px;
      }
      &.current,&:hover,&:focus{
        background-color: #627597;
        border-color: #627597;
        color: $white;
        text-decoration: none;
      }
    }
  }

  &__keywords-item{
    @include media-down(lg) {
      min-width: calc(25% - 0.75em);
    }
  }
}

.recruit-detail {
  max-width: 960px;
  margin: 0 auto;
  font-size: calc(15 / 16 * $font-size-base);
  line-height: 1.9;
  letter-spacing: 0.12em;
  font-feature-settings: "palt";

  h4 {
    position: relative;
    font-size: min(4.8vw, calc(22 / 16 * $font-size-base));
    color: #627597;
    letter-spacing: 0.24em;
    font-weight: $font-weight-bold;
    padding-left: 1em;
    margin-bottom: 1.5em;
    &:before {
      content: '';
      position: absolute;
      top: 0.6em;
      left: 0;
      width: 0.4em;
      height: 2px;
      background-color: currentColor;
    }
  }
}

.recruit-table-typeA {
  @include table-softpren-typeA;
}

.recruit-table-typeB{
  @include table-softpren-typeB;
}


.recruit-table-scrollable {
  @include media-down(lg) {
    position: relative;
    max-width: 100%;
    padding-top: 30px;
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      content: "";
      background: svgUrlFunc(
          '<svg viewBox="0 0 52.74 16.16" xmlns="http://www.w3.org/2000/svg"><g fill="#{$body-color}"><path d="m52.74 16.16h-52.74v-1h50.96l-2.54-4.19.86-.52z"/><path d="m5.44 7.32c0-.29-.11-.52-.33-.68s-.61-.3-1.15-.42-.98-.26-1.31-.42-.56-.36-.71-.59-.23-.5-.23-.81c0-.52.22-.96.66-1.32s1-.54 1.68-.54c.72 0 1.3.19 1.75.56s.67.85.67 1.42h-1.09c0-.3-.13-.55-.38-.77s-.57-.32-.95-.32-.7.09-.93.26-.33.4-.33.67c0 .26.1.46.31.59s.58.26 1.12.38.98.27 1.32.43.58.37.75.61.24.52.24.86c0 .57-.23 1.02-.68 1.36s-1.04.51-1.76.51c-.51 0-.96-.09-1.35-.27s-.7-.43-.92-.75-.33-.67-.33-1.05h1.08c.02.36.17.65.44.86s.63.32 1.08.32c.41 0 .74-.08.99-.25s.37-.39.37-.67z"/><path d="m11.92 8.23c.39 0 .72-.12 1.01-.35s.45-.53.48-.88h1.03c-.02.36-.14.71-.38 1.04s-.54.59-.92.79-.79.29-1.22.29c-.86 0-1.55-.29-2.06-.86s-.76-1.36-.76-2.36v-.18c0-.62.11-1.17.34-1.65s.55-.85.98-1.12.92-.4 1.5-.4c.71 0 1.3.21 1.77.64s.72.98.75 1.66h-1.03c-.03-.41-.19-.75-.47-1.01s-.62-.4-1.03-.4c-.55 0-.98.2-1.28.59s-.45.97-.45 1.72v.21c0 .73.15 1.29.45 1.69s.73.59 1.29.59z"/><path d="m20.16 3.63c-.16-.03-.34-.04-.53-.04-.71 0-1.19.3-1.45.91v4.5h-1.08v-6.34h1.05l.02.73c.36-.57.86-.85 1.51-.85.21 0 .37.03.48.08z"/><path d="m22.31 5.77c0-.62.12-1.18.37-1.68s.58-.88 1.02-1.15.93-.4 1.49-.4c.86 0 1.56.3 2.09.9s.8 1.39.8 2.38v.08c0 .62-.12 1.17-.35 1.66s-.57.87-1.01 1.15-.94.41-1.51.41c-.86 0-1.56-.3-2.09-.9s-.8-1.39-.8-2.37v-.08zm1.09.13c0 .7.16 1.27.49 1.69s.76.64 1.31.64.99-.22 1.31-.65.49-1.04.49-1.81c0-.7-.17-1.26-.5-1.69s-.77-.65-1.32-.65-.97.21-1.29.64-.49 1.04-.49 1.83z"/><path d="m32.06 9h-1.08v-9h1.08z"/><path d="m36.41 9h-1.08v-9h1.08z"/></g></svg>'
        )
        center / cover no-repeat;
      width: 53px;
      height: 16px;
      animation: scroll_table_icon 1s infinite ease-in-out alternate-reverse;
    }
  }
  &__inner {
    @include media-down(lg) {
      overflow: auto;
    }
  }
  &__table {
    @include media-down(lg) {
      width: 800px;
    }
  }
}