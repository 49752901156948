@mixin table-softpren-typeA {
  width: 100%;
  margin-bottom: map-get($spacers, 6);
  font-size: calc(14 / 16 * $font-size-base);
  letter-spacing: 0.15em;
  font-feature-settings: "palt";
  th,td {
    border: solid #E9E9E9;
    border-width: 1px 0;
    padding: 1.3em 1em;
    vertical-align: top;
    @include media-down(lg) {
      display: block;
      padding-left: 0;
      padding-right: 0;
    }
  }
  th {
    letter-spacing: 0.3em;
    white-space: nowrap;
    @include media-up(lg) {
      width: 25%;
    }
    @include media-down(lg) {
      border-width: 0;
      padding-bottom: 0;
    }
  }
  td {
    @include media-up(lg) {
      padding-right: 3em;
    }
    @include media-down(lg) {
      border-top-width: 0;
      padding-top: 0.2em;
    }
  }
  tr:first-child th,
  tr:first-child td {
    border-top-width: 0;
  }
  tr:last-child th,
  tr:last-child td {
    border-bottom-width: 0;
  }
  th:first-child,
  td:last-child {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: calc(100% - 0.5px);
      left: 0;
      width: 10em;
      height: 0;
      border-bottom: 1px solid #C0C1C1;
    }
  }
  th:first-child:after{
    @include media-down(lg) {
      display: none;
    }
  }
  td:last-child:after {
    @include media-up(lg) {
      display: none;
    }
  }
  tr:last-child th:first-child:after {
    display: none;
  }
  tr:last-child td:last-child:after {
    display: none;
  }
}

@mixin table-softpren-typeB {
  width: 100%;
  th,td {
    padding: 1em;
    font-size: calc(13 / 16 * $font-size-base);
    vertical-align: top;
  }
  thead th{
    background-color: #627597;
    color: $white;
    @include media-up(lg) {
      white-space: nowrap;
    }
  }
  tbody tr:nth-child(odd){
    background-color: #F9F9F9;
  }
  tbody th,
  tbody td{
    border-top: 1px solid #E9E9E9;
  }
}
