@mixin c-module-title() {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.75em;
  font-weight: normal;
  letter-spacing: 0.22em;
  text-indent: 0.22em;
  font-size: $h2-font-size;
  @include media-down(lg) {
    font-size: 1.875rem;
  }
  @include media-down(md) {
    font-size: $h4-font-size;
  }

  span {
    font-family: "Roboto", $font-family-sans-serif;
    font-weight: $font-weight-normal;
  }

  &:after{
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    display: block;
    width: 82px;
    height: 1px;
    margin: auto;
    background: $primary;
  }
}

@mixin c-block-title ($theme: $primary) {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1em;
  font-size: min(6.93vw, calc(40 / 16 * $font-size-base));
  letter-spacing: 0.14em;
  color: $theme;
  span{
    font-family: 'Montserrat', $font-family-base;
    font-weight: $font-weight-medium;
  }
  small {
    font-size: min(3.46vw, calc(15 / 16 * $font-size-base));
    letter-spacing: 0.19em;
    margin-top: 0.5em;
  }
  
}

@mixin c-section-title ($theme: $body-color, $subtheme: $primary){
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: min(6.66vw, calc(30 / 16 * $font-size-base));
  font-weight: $font-weight-bold;
  letter-spacing: 0.2em;
  text-indent: 0.2em;
  font-feature-settings: "palt";
  margin-bottom: 2em;
  color: $theme;
  small {
    font-size: calc(12 / 16 * $font-size-base);
    font-family: 'Jost', $font-family-base;
    letter-spacing: 0.18em;
    text-indent: 0.18em;
    margin-top: 1em;
    color: $subtheme;
    font-weight: $font-weight-medium;
  }
}