@keyframes scroll_table_icon {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(10px);
  }
}

.is--about {
  .rwd002-pagination {
    display: none;
  }
}

.about-intro {
  padding: map-get($spacers, 6) map-get($spacers, 3);
  background-color: #f6f8fa;
  @include media-down(lg) {
    padding-top: map-get($spacers, 4);
    padding-bottom: map-get($spacers, 4);
  }
  &__inner {
    display: flex;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
    column-gap: map-get($spacers, 6);
    row-gap: map-get($spacers, 4);
    @include media-down(lg) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__title {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 0.7em;
    margin-bottom: 0.9em;
    font-size: min(6.66vw, calc(34 / 16 * $font-size-base));
    font-weight: $font-weight-bold;
    letter-spacing: 0.14em;
    &:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: 0.7em;
      border-bottom: 1px solid $primary;
    }
    small {
      font-size: min(2.93vw, calc(14 / 16 * $font-size-base));
      font-family: "Montserrat", $font-family-base;
      color: $primary;
      letter-spacing: 0.1em;
      margin-bottom: 1em;
    }
  }

  &__img {
    flex-shrink: 0;
    &:last-child {
      margin-bottom: 0;
    }
    @include media-up(lg) {
      max-width: 53vw;
    }
    @include media-down(lg) {
      align-self: center;
    }
    figcaption {
      text-align: center;
      font-size: min(4.26vw, calc(17 / 16 * $font-size-base));
      letter-spacing: 0.24em;
      text-indent: 0.24em;
      margin-bottom: 1em;
    }
    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  &__body {
    font-feature-settings: "palt";
    .lead {
      font-weight: $font-weight-bold;
      font-size: min(4vw, calc(17 / 16 * $font-size-base));
      letter-spacing: 0.14em;
      line-height: 2;
      &:last-child {
        margin-bottom: 0;
      }
      @include media-down(lg) {
        letter-spacing: 0.12em;
      }
    }
  }
}

.about-strength {
  margin-top: map-get($spacers, 6);
  overflow: hidden;

  &__head {
    padding: 0 map-get($spacers, 3);
    margin-bottom: map-get($spacers, 6);
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: min(8.8vw, calc(40 / 16 * $font-size-base));
    font-family: "Montserrat", $font-family-base;
    font-weight: $font-weight-bold;
    letter-spacing: 0.14em;
    text-indent: 0.14em;
    font-feature-settings: "palt";
    color: $primary;
    small {
      margin-top: 1em;
      font-size: min(3.73vw, $font-size-base);
      letter-spacing: 0.18em;
      text-indent: 0.18em;
    }
  }
}

.about-strength-item {
  margin-bottom: map-get($spacers, 7);
  &:last-child {
    margin-bottom: 0;
  }
  @include media-down(lg) {
    padding: 0 map-get($spacers, 3);
    margin-bottom: map-get($spacers, 5);
  }

  &__body {
    grid-area: body;
    font-feature-settings: "palt";

    .num {
      display: block;
      margin-bottom: min(5.33vw, 35px);
      width: min(12.8vw, 87px);
      height: auto;
      @include media-down(lg) {
        margin-left: auto;
        margin-right: auto;
      }
    }
    .title {
      font-size: min(5.6vw, calc(34 / 16 * $font-size-base));
      font-weight: $font-weight-bold;
      letter-spacing: 0.1em;
      margin-bottom: 0.8em;
      line-height: 1.57;
      @include media-down(lg) {
        text-align: center;
      }
    }
    .text {
      line-height: 1.86;
      letter-spacing: 0.12em;
      font-size: calc(15 / 16 * $font-size-base);
      &:last-child {
        margin-bottom: 0;
      }
    }

    .iso {
      display: flex;
      align-items: center;
      margin-top: 1.5em;
      margin-bottom: 1.5em;
      column-gap: map-get($spacers, 2);
      img {
        height: auto;
        max-width: calc(50% - (map-get($spacers, 2) / 2));
      }
    }
  }

  &__img {
    grid-area: img;
  }

  // is-item01
  &__customer {
    grid-area: customer;
    max-width: 960px;
    padding-top: map-get($spacers, 4);
    margin-top: map-get($spacers, 4);
    border-top: 1px solid #e8e8e8;
    .title {
      text-align: center;
      font-size: calc(17 / 16 * $font-size-base);
      letter-spacing: 0.24em;
      text-indent: 0.24em;
      margin-bottom: 2em;
      font-weight: $font-weight-bold;
      @include media-down(lg) {
        margin-bottom: 1em;
      }
    }
    &__list {
      max-width: 800px;
      margin: 0 auto;
      font-size: calc(14 / 16 * $font-size-base);
      font-weight: $font-weight-bold;
      letter-spacing: 0.1em;
      list-style: none;
      @include media-up(lg) {
        padding-left: 50px;
        column-count: 2;
        column-gap: 5em;
      }
      li {
        position: relative;
        margin-bottom: 0.7em;
        padding-left: 1.5em;
        &:before {
          content: "";
          position: absolute;
          top: 0.4em;
          left: 0;
          height: 0.64em;
          width: 0.64em;
          background-color: #e0e2e2;
          border-radius: 100%;
        }
      }
    }
    .notice {
      max-width: 700px;
      margin: -1em auto 0;
      text-align: right;
      font-size: 0.8em;
      font-weight: $font-weight-bold;
      color: tint($body-color, 20%);
    }
  }

  // is-item02
  &__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: calc(100% - 125px);
    background-color: #0a2656;
    transform: scale(0, 1);
    transition: transform 0.5s 0.3s ease-in-out;
    transform-origin: left;
    @include media-down(lg) {
      width: 100%;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      background: url("/assets/img/about/about-strength02_bg@2x.jpg") center / contain no-repeat;
      width: 380px;
      height: 300px;
      z-index: -1;
      transform-origin: right top;
      transition: all 0.8s 0.8s ease-in-out;
      filter: blur(5px);
      transform: scale(1.2);
      opacity: 0;
    }
    .is-intersection & {
      transform: scale(1, 1);
      &:after {
        transform: scale(1);
        filter: blur(0px);
        opacity: 1;
      }
    }
  }

  // is-item05
  &__dataTable {
    @include media-up(lg) {
      max-width: 860px;
      margin: 0 auto;
    }
    &__table {
      width: 100%;
      font-feature-settings: "palt";
      th,
      td {
        border: 1px solid tint($quaternary, 70%);
        padding: 0.8em 1.5em;
        vertical-align: middle;
        font-size: calc(14 / 16 * $font-size-base);
        letter-spacing: 0.15em;
      }
      thead {
        text-align: center;
        color: $white;
        background-color: $quaternary;
        letter-spacing: 0.3em;
        text-indent: 0.3em;
      }
    }
    &.is-scrollable {
      @include media-down(lg) {
        position: relative;
        max-width: 100%;
        padding-top: 30px;
        &:after {
          position: absolute;
          top: 0;
          right: 0;
          content: "";
          background: svgUrlFunc(
              '<svg viewBox="0 0 52.74 16.16" xmlns="http://www.w3.org/2000/svg"><g fill="#{$body-color}"><path d="m52.74 16.16h-52.74v-1h50.96l-2.54-4.19.86-.52z"/><path d="m5.44 7.32c0-.29-.11-.52-.33-.68s-.61-.3-1.15-.42-.98-.26-1.31-.42-.56-.36-.71-.59-.23-.5-.23-.81c0-.52.22-.96.66-1.32s1-.54 1.68-.54c.72 0 1.3.19 1.75.56s.67.85.67 1.42h-1.09c0-.3-.13-.55-.38-.77s-.57-.32-.95-.32-.7.09-.93.26-.33.4-.33.67c0 .26.1.46.31.59s.58.26 1.12.38.98.27 1.32.43.58.37.75.61.24.52.24.86c0 .57-.23 1.02-.68 1.36s-1.04.51-1.76.51c-.51 0-.96-.09-1.35-.27s-.7-.43-.92-.75-.33-.67-.33-1.05h1.08c.02.36.17.65.44.86s.63.32 1.08.32c.41 0 .74-.08.99-.25s.37-.39.37-.67z"/><path d="m11.92 8.23c.39 0 .72-.12 1.01-.35s.45-.53.48-.88h1.03c-.02.36-.14.71-.38 1.04s-.54.59-.92.79-.79.29-1.22.29c-.86 0-1.55-.29-2.06-.86s-.76-1.36-.76-2.36v-.18c0-.62.11-1.17.34-1.65s.55-.85.98-1.12.92-.4 1.5-.4c.71 0 1.3.21 1.77.64s.72.98.75 1.66h-1.03c-.03-.41-.19-.75-.47-1.01s-.62-.4-1.03-.4c-.55 0-.98.2-1.28.59s-.45.97-.45 1.72v.21c0 .73.15 1.29.45 1.69s.73.59 1.29.59z"/><path d="m20.16 3.63c-.16-.03-.34-.04-.53-.04-.71 0-1.19.3-1.45.91v4.5h-1.08v-6.34h1.05l.02.73c.36-.57.86-.85 1.51-.85.21 0 .37.03.48.08z"/><path d="m22.31 5.77c0-.62.12-1.18.37-1.68s.58-.88 1.02-1.15.93-.4 1.49-.4c.86 0 1.56.3 2.09.9s.8 1.39.8 2.38v.08c0 .62-.12 1.17-.35 1.66s-.57.87-1.01 1.15-.94.41-1.51.41c-.86 0-1.56-.3-2.09-.9s-.8-1.39-.8-2.37v-.08zm1.09.13c0 .7.16 1.27.49 1.69s.76.64 1.31.64.99-.22 1.31-.65.49-1.04.49-1.81c0-.7-.17-1.26-.5-1.69s-.77-.65-1.32-.65-.97.21-1.29.64-.49 1.04-.49 1.83z"/><path d="m32.06 9h-1.08v-9h1.08z"/><path d="m36.41 9h-1.08v-9h1.08z"/></g></svg>'
            )
            center / cover no-repeat;
          width: 53px;
          height: 16px;
          animation: scroll_table_icon 1s infinite ease-in-out alternate-reverse;
        }
      }
    }
    &.is-scrollable & {
      &__inner {
        @include media-down(lg) {
          overflow: auto;
        }
      }
      &__table {
        @include media-down(lg) {
          width: 800px;
        }
      }
    }
  }
}

.about-strength-item.is-item01 {
  display: grid;
  @include media-up(lg) {
    max-width: calc(960px + (map-get($spacers, 3) * 2));
    margin-left: auto;
    margin-right: auto;
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 3);
    column-gap: map-get($spacers, 5);
    grid-template:
      "body     img     " auto
      "customer customer" auto
      / min(50vw, 480px) 1fr;
  }
  @include media-down(lg) {
    grid-template:
      "img" auto
      "body" auto
      "customer" auto
      / auto;
    row-gap: map-get($spacers, 3);
  }
  .about-strength-item__img {
    @include media-down(lg) {
      margin-left: -1 * map-get($spacers, 3);
      margin-right: -1 * map-get($spacers, 3);
    }
    img {
      display: block;
      height: auto;
      @include media-down(lg) {
        max-width: 100%;
        margin-top: max(-50px, -15vw);
        margin-left: auto;
        margin-right: auto;
      }
      @include media-up(lg) {
        width: min(800px, 46vw);
        margin-top: max(-80px, -10vw);
      }
    }
  }
}

.about-strength-item.is-item02 {
  position: relative;
  display: flex;
  column-gap: map-get($spacers, 6);
  row-gap: map-get($spacers, 4);
  padding: map-get($spacers, 4) map-get($spacers, 3) map-get($spacers, 4) 0;
  z-index: 0;
  color: $white;
  @include media-up(lg) {
    align-items: center;
  }
  @include media-down(lg) {
    flex-direction: column;
    padding: map-get($spacers, 4) map-get($spacers, 3);
  }

  .about-strength-item__body {
    order: 2;
    @include media-up(lg) {
      max-width: min(480px, calc(50vw - 125px - map-get($spacers, 3)));
    }
  }

  .about-strength-item__img {
    order: 1;
    @include media-up(lg) {
      width: calc(50vw - map-get($spacers, 6));
    }
    @include media-down(lg) {
      margin-left: -1 * map-get($spacers, 2);
      margin-right: -1 * map-get($spacers, 2);
    }
    img {
      display: block;
      @include media-up(lg) {
        height: 393px;
        width: 100%;
        object-fit: cover;
      }
      @include media-down(lg) {
        margin: 0 auto;
        max-width: 100%;
      }
    }
  }
}

.about-strength-item.is-item03 {
  display: flex;
  column-gap: map-get($spacers, 6);
  row-gap: map-get($spacers, 4);
  @include media-up(lg) {
    align-items: center;
    flex-direction: row-reverse;
    padding-left: map-get($spacers, 3);
  }
  @include media-down(lg) {
    flex-direction: column;
    padding: 0 map-get($spacers, 3);
  }

  .about-strength-item__body {
    order: 2;
    @include media-up(lg) {
      max-width: 480px;
    }
  }

  .about-strength-item__img {
    order: 1;
    @include media-up(lg) {
      width: calc(50vw - map-get($spacers, 6));
    }
    @include media-down(lg) {
      margin-left: -1 * map-get($spacers, 2);
      margin-right: -1 * map-get($spacers, 2);
    }
    img {
      display: block;
      @include media-up(lg) {
        height: 490px;
        width: 100%;
        object-fit: cover;
      }
      @include media-down(lg) {
        margin: 0 auto;
        max-width: 100%;
      }
    }
  }
}

.about-strength-item.is-item04 {
  display: flex;
  column-gap: map-get($spacers, 6);
  row-gap: map-get($spacers, 4);
  @include media-up(lg) {
    align-items: center;
    padding-right: map-get($spacers, 3);
  }
  @include media-down(lg) {
    flex-direction: column;
    padding: 0 map-get($spacers, 3);
  }

  .about-strength-item__body {
    order: 2;
    @include media-up(lg) {
      max-width: 480px;
    }
  }

  .about-strength-item__img {
    order: 1;
    @include media-up(lg) {
      width: calc(50vw - map-get($spacers, 6));
    }
    @include media-down(lg) {
      margin-left: -1 * map-get($spacers, 2);
      margin-right: -1 * map-get($spacers, 2);
    }
    img {
      display: block;
      @include media-up(lg) {
        height: 490px;
        width: 100%;
        object-fit: cover;
      }
      @include media-down(lg) {
        margin: 0 auto;
        max-width: 100%;
      }
    }
  }
}

.about-strength-item.is-item05 {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 map-get($spacers, 3) map-get($spacers, 4);
  z-index: 0;
  row-gap: map-get($spacers, 4);
  @include media-down(lg) {
    padding: map-get($spacers, 4) map-get($spacers, 3);
  }
  &:after {
    content: "";
    position: absolute;
    top: 125px;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #f6f8fa;
    z-index: -1;
  }

  .about-strength-item__img {
    order: 1;
    @include media-up(lg) {
      margin-right: -1 * map-get($spacers, 3);
      margin-left: -1 * map-get($spacers, 3);
      margin-bottom: map-get($spacers, 3);
      padding-left: 125px;
    }
    @include media-down(lg) {
      margin-right: -1 * map-get($spacers, 2);
      margin-left: -1 * map-get($spacers, 2);
    }
    img {
      display: block;
      max-width: 100%;
      @include media-up(lg) {
        width: 100%;
        height: 462px;
        object-fit: cover;
      }
      @include media-down(lg) {
        margin: 0 auto;
      }
    }
  }

  .about-strength-item__body {
    order: 2;
    text-align: center;
    .num {
      margin-left: auto;
      margin-right: auto;
    }
    @include media-up(lg) {
      max-width: 860px;
      margin: 0 auto;
    }
  }

  .about-strength-item__dataTable {
    order: 3;
  }
}

// お客様対応について
.service-intro {
  background-color: #f6f8fa;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  padding: map-get($spacers, 4) map-get($spacers, 3) map-get($spacers, 6);
  @include media-up(lg) {
    padding: map-get($spacers, 6) map-get($spacers, 3) 120px;
    align-items: center;
    text-align: center;
  }
  &__title {
    margin-bottom: map-get($spacers, 4);
    @include media-down(lg) {
      margin-bottom: map-get($spacers, 3);
    }
    img {
      display: block;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
    }
  }
  .text {
    line-height: 2;
    font-size: calc(15 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.service-solution {
  background-color: #09246c;
  color: $white;
  padding: map-get($spacers, 7) 0;
  margin-bottom: map-get($spacers, 7);
  @include media-down(lg) {
    padding: 0 0 map-get($spacers, 5);
    margin-bottom: map-get($spacers, 5);
  }
  &__head {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding-left: map-get($spacers, 3);
    margin-bottom: map-get($spacers, 6);
    column-gap: map-get($spacers, 6);
    row-gap: map-get($spacers, 4);
    @include media-down(lg) {
      flex-direction: column;
      padding-right: map-get($spacers, 3);
      margin-bottom: map-get($spacers, 5);
    }
  }
  &__body {
    font-feature-settings: "palt";
    @include media-up(lg) {
      max-width: min(46vw, 530px);
    }
    @include media-down(lg) {
      order: 2;
    }
    .text {
      font-size: calc(15 / 16 * $font-size-base);
      line-height: 1.9;
      letter-spacing: 0.12em;
      @include media-down(lg) {
        margin-right: -0.2em;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__title {
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: min(8vw, calc(45 / 16 * $font-size-base));
    font-weight: $font-weight-bold;
    letter-spacing: 0.18em;
    margin-bottom: 1em;
    z-index: 0;
    @include media-down(lg) {
      text-align: center;
    }
    &:before {
      content: "";
      position: absolute;
      top: -90px;
      left: -100px;
      z-index: -1;
      background: url("/assets/img/about/about-cs-logo.svg") center / contain no-repeat;
      width: 172px;
      height: 200px;
      aspect-ratio: 172/200;
      @include media-down(lg) {
        height: auto;
        width: 85px;
        top: -27px;
        left: -15px;
      }
    }
    span {
      font-size: 0.57em;
      letter-spacing: 0.18em;
      text-indent: 0;
      line-height: 1.5;
      margin-bottom: 1em;
    }
  }
  &__img {
    @include media-up(lg) {
      height: 504px;
      width: calc(50vw - map-get($spacers, 6) - 50px);
      margin-top: calc(-1 * (map-get($spacers, 7) + 50px));
    }
    @include media-down(lg) {
      order: 1;
      margin-top: -40px;
      margin-left: auto;
      margin-right: calc(-1 * map-get($spacers, 3));
      width: 80vw;
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__inner {
    max-width: calc(960px + map-get($spacers, 3) * 2);
    margin: 0 auto;
    padding: 0 map-get($spacers, 3);
    display: flex;
    column-gap: map-get($spacers, 3);
    @include media-down(lg) {
      flex-direction: column;
    }
  }

  &__listTitle {
    margin: 0;
    flex-shrink: 0;
    @include media-up(lg) {
      margin-right: auto;
    }
    @include media-down(lg) {
      margin-bottom: map-get($spacers, 3);
    }
    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
      @include media-up(lg) {
        width: 56px;
      }
    }
  }

  &__list {
    display: flex;
    column-gap: map-get($spacers, 3);
    row-gap: map-get($spacers, 3);
    list-style: none;
    padding: 0;
    @include media-down(lg) {
      flex-direction: column;
    }
  }
  &__result {
    font-feature-settings: "palt";
    @include media-up(lg) {
      max-width: 250px;
    }
    img {
      display: block;
      max-width: 100%;
      height: auto;
      margin-bottom: 1.5em;
    }
    .desc {
      font-size: calc(15 / 16 * $font-size-base);
      letter-spacing: 0.12em;
      line-height: 1.8;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.service-staff {
  &__title {
    @include c-section-title();
  }
  &__list {
    margin-top: -1 * map-get($spacers, 5);
  }
}

.service-person {
  padding: map-get($spacers, 5) map-get($spacers, 3);
  &:nth-child(even){
    background-color: #F6F8FA;
  }
  &__inner {
    display: flex;
    column-gap: 35px;
    row-gap: 30px;
    max-width: 960px;
    margin: 0 auto;
    @include media-up(lg) {
      align-items: center;
    }
    @include media-down(lg) {
      flex-direction: column;
    }
  }
  &:nth-child(odd) &__inner {
    @include media-up(lg) {
      flex-direction: row-reverse;
    }
  }
  &__body {
    flex-grow: 1;
    @include media-down(lg) {
      order: 2;
    }
  }

  &__name {
    display: flex;
    flex-direction: column;
    font-size: calc(29 / 16 * $font-size-base);
    font-weight: $font-weight-bold;
    letter-spacing: 0.14em;
    margin-bottom: 1.2em;
    color: #09246C;
    .title {
      font-size: 0.448em;
      letter-spacing: 0.14em;
      margin-bottom: 0.5em;
    }
    .name {
      white-space: nowrap;
      small{
        font-size: 0.413em;
        color: tint($body-color, 80%);
        font-family: 'Roboto', $font-family-base;
        letter-spacing: 0.18em;
        margin-left: 1.5em;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  &__profile {
    font-feature-settings: "palt";
    margin-bottom: 2em;
    &:last-child {
      margin-bottom: 0;
    }
    dt{
      font-size: calc(18 / 16 * $font-size-base);
      font-weight: $font-weight-bold;
      letter-spacing: 0.16em;
      margin-bottom: 0.5em;
      &:before {
        display: inline-block;
        content: '';
        background: svgUrlFunc('<svg viewBox="0 0 15.56 15.56" xmlns="http://www.w3.org/2000/svg"><path d="m7.78 4.24 3.54 3.54-3.54 3.54-3.54-3.54zm0-4.24-7.78 7.78 7.78 7.78 7.78-7.78z" fill="#09246c"/></svg>') center / contain no-repeat;
        height: 16px;
        width: 16px;
        margin-right: 0.3em;
        margin-bottom: -0.05em;
      }
    }
    dd{
      margin: 0;
      font-size: calc(15 / 16 * $font-size-base);
      letter-spacing: 0.12em;
      line-height: 1.9;
    }
  }

  &__img {
    position: relative;
    align-self: center;
    z-index: 0;
    max-width: 84.64%;
    flex-shrink: 0;
    @include media-down(lg) {
      order: 1;
    }
    &:before {
      content: '';
      position: absolute;
      background-color: $primary;
      top: -10px;
      left: -10px;
      width: min(25vw, 105px);
      height: min(25vw, 105px);
      z-index: -1;
    }
    img{
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
}
