.rwd002-fixNav {
  position: fixed;
  transition: $transition-base;
  z-index: 2;
  
  @include media-up(lg) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    bottom: map-get($spacers, 4);
    right: map-get($spacers, 3);
  }

  @include media-down(lg) {
    bottom: 0;
    display: flex;
    align-items: stretch;
    width: 100%;
    z-index: 11;
    transition: all 0.2s ease-in-out;
    transform: translateY(100%);
  }

  &.hidden {
    @include media-up(lg) {
      transform: translateY( calc(100% + map-get($spacers, 4)) );
    }
  }

  &.is-active {
    @include media-down(lg) {
      opacity: 1;
      display: flex;
      transform: translateY(0);
    }
  }

  &.is-close {
    opacity: 0;
    @include media-up(lg) {
      transform: translateY( calc(100% - map-get($spacers, 4)) );
    }
    @include media-down(lg) {
      transform: translateY(100%);
    }
  }

  .btn-close {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    position: absolute;
    top: 15px;
    right: 15px;
    border-radius: 100%;
    padding: 0;
    width: 24px;
    height: 24px;
    line-height: 1;
    opacity: 1;
    z-index: 0;
    @include media-down(lg) {
      display: none;
      top: -0.5em;
    }
    &:before{ 
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      aspect-ratio: 1;
      background-color: $white;
      border-radius: 100%;
      z-index: -1;
    }
  }
  &.is-active .btn-close{
    @include media-down(lg) {
      display: block;
    }
  }

  &__inquiry,
  &__entry {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    font-size: $font-size-base;
    letter-spacing: 0.1em;
    transition: $transition-base;
    row-gap: 0.8em;
    column-gap: 1em;
    background-color: darken($primary, 10%);
    color: $white;
    
    @include media-up(lg) {
      width: 165px;
      height: 165px;
      aspect-ratio: 1;
      border: 2px solid darken($primary, 10%);
      flex-direction: column;
      border-radius: 100%;
      box-shadow: 0 3px 20px fade-out($body-color, 0.5);
    }

    @include media-down(lg) {
      width: 100%;
      padding: 1rem;
      color: $white;
      background-color: $primary;
      font-size: calc(15 / 16 * $font-size-base);
    }

    span {
    }

    .icon {
      @include media-up(lg) {
        color: inherit;
      }
      @include media-down(lg) {
        width: 23px;
        height: 15px;
      }
    }

    &:hover,
    &:focus {
      background-color: $white;
      text-decoration: none;
      .icon {
        color: $primary;
      }
    }
  }

  &__pagetop {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $body-color;
    color: $white;
    font-family: "Roboto", $font-family-sans-serif;
    transition: $transition-base;
    letter-spacing: 0.1em;
    text-indent: 0.1em;

    @include media-up(lg) {
      position: absolute;
      right: 0;
      bottom: -171px;
      width: 64px;
      height: 64px;
      transform: translateY(100%);
    }

    @include media-down(lg) {
      min-width: 64px;
      font-size: $font-size-sm;
    }

    &:hover,
    &:focus {
      text-decoration: none;
      color: $white;
      background-color: darken($body-color, 10%);
    }
  }

  &__entry {
    background-color: $secondary;
    border-color: $secondary;
    &:hover,&:focus{
      color: $secondary;
    }
  }
}